import {
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Skeleton
} from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import ReservationPageWrapper from "../../../assets/wrappers/ReservationPageWrapper";
import { useBookingContext } from "../../../context/booking/bookingContext";
import { useAuthContext } from "../../../context/auth/authContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ReservationBody from "./ReservationBody";
import hrLocale from "date-fns/locale/hr";
const CreateReservation = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const { reservationIdentification, itemIdentification } = useParams();
  const isReservationCurrentlyEdited = pathname.includes("edit");


  const {
    shifts,
    setStateShifts,
    services,
    setStateServices,
    selectedService,
    setSelectedService,
    setStateSubServices,
    selectedShift,
    setSelectedShift,
    orderDate,
    setOrderDate,
    setCurrentlyEditedReservation,
    activeReservations,
    setActiveReservationColor,

  } = useBookingContext();

  const {handleActiveReservationListChange} = useAuthContext();

  const handleShiftChange = (e) => {
    setSelectedShift(e.target.value);
  };

  const handleSubServiceDisplay = (e, newVal) => {
    setSelectedService(newVal);
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    setIsLoading(true)
    const getInitialData = async () => {
      try {
       const orderDataResponse = await axiosPrivate("/order/data", {
          signal: controller.signal,
        })
        if (orderDataResponse.data.code === 200 && isMounted) {
          setStateShifts(orderDataResponse.data.data.shifts || []);
          setStateServices(orderDataResponse.data.data.services || []) ;
          setStateSubServices(orderDataResponse.data.data.subservices || []);

        }

        if(isReservationCurrentlyEdited) {
          const editResponse = await axiosPrivate.patch(
              `/order/editor/${reservationIdentification}`)

          if (editResponse?.data.code === 200 && orderDataResponse.data.code === 200 && isMounted) {
            setCurrentlyEditedReservation(editResponse.data.data);
            !activeReservations?.includes(editResponse.data.data.id) &&
            handleActiveReservationListChange(editResponse.data.data.id);
            setActiveReservationColor(editResponse.data.data.id);

            let itemObject;
            if (itemIdentification) {
              itemObject = editResponse.data.data.items.find(item => item.id === itemIdentification)
            } else {
              itemObject = editResponse.data.data.items[0];
            }



            const serviceObject = orderDataResponse?.data.data.services?.find(service => service.id === itemObject?.service)
            setOrderDate(itemObject.date ? itemObject.date : orderDate)
            setSelectedShift(serviceObject?.shift);
            setSelectedService(serviceObject || null)
          }

        } else {
          setCurrentlyEditedReservation({ status: 1, items: [] });
          setActiveReservationColor("");
          setOrderDate(moment(new Date).format("YYYY-MM-DD"))
        }

      } catch (error) {
        console.log(error);
      }

      setIsLoading(false)
    };

    getInitialData();


    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [itemIdentification]);


  return (
    <ReservationPageWrapper container>
      {!isLoading ?

          <Grid item xs={12} className='first-grid'>

            {shifts.length > 0 ? (
                <ToggleButtonGroup
                    color='primary'
                    exclusive
                    onChange={handleShiftChange}
                    value={selectedShift}
                    className={"customToggleButtoNGroup"}
                    sx={{
                      padding: "0 15px 20px 0",
                    }}
                >
                  {shifts.map((s) => {
                    return (
                        <ToggleButton key={s.id} value={s.id} name='shift'>
                          {s.name}
                        </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
            ) : (
                <h3>Please create a shift for this franchise...</h3>
            )}

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={hrLocale}>
              <MobileDatePicker
                  label='Date'
                  className='datepicker-cstm'
                  // disablePast={isReservationCurrentlyEdited ? false : true}
                  value={orderDate}
                  onChange={(newValue) => {
                    setOrderDate(
                        moment(newValue).format("YYYY-MM-DD")
                    );

                  }}

                  renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          : <Skeleton width="100%" height="100px"/>
      }


      {
        !isLoading ?
            <Grid item xs={12}>
              <ToggleButtonGroup
                  color='primary'
                  exclusive
                  onChange={handleSubServiceDisplay}
                  value={selectedService}
                  className={"customToggleButtoNGroup"}
                  sx={{
                    padding: "0 15px 20px 0",
                  }}
              >
                {selectedShift &&
                    services
                        .filter((service) => service.shift === selectedShift)
                        .map((ser) => {
                          return (
                              <ToggleButton key={ser.id} value={ser}>
                                {ser.name}
                              </ToggleButton>
                          );
                        })}
              </ToggleButtonGroup>
            </Grid>

            :

            <Skeleton width="100%" height="100px"/>

      }

      {
        !isLoading ?
            <Grid item xs={12}>
              {selectedService && (
                  <ReservationBody
                      selectedService={selectedService}
                      reservationIdentification={reservationIdentification}
                      isReservationCurrentlyEdited={isReservationCurrentlyEdited}
                      shifts={shifts}
                      services={services}
                      orderDate={orderDate}
                  />
              )}
            </Grid>

            :
            <Skeleton width="100%" height="850px" sx={{marginTop:"-150px"}}/>
      }

    </ReservationPageWrapper>
  );
};

export default CreateReservation;
