import { TextField, FormControlLabel, Checkbox, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CustomAlert from "./helpers/CustomAlert";

const LoginForm = ({ handleChange, onSubmit, errors, showAlert }) => {
  return (
    <form
      noValidate
      autoComplete='off'
      className='login-form'
      onSubmit={onSubmit}
    >
      {showAlert && <CustomAlert />}
      <TextField
        onChange={handleChange}
        label='Username'
        name='username'
        margin='normal'
        variant='standard'
        fullWidth
        required
        autoFocus
        error={errors?.username}
        helperText={errors?.username ? "Please Enter username!" : ""}
      />
      <TextField
        onChange={handleChange}
        label='Password'
        name='password'
        margin='normal'
        variant='standard'
        type='password'
        fullWidth
        required
        error={errors?.password}
        helperText={errors?.password ? "Please enter password!" : ""}
      />

      <Button
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        size='large'
        endIcon={<SendIcon />}
      >
        Submit
      </Button>
    </form>
  );
};
export default LoginForm;
