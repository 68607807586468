
import { useState } from "react";

// material

import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,

} from "@mui/material";

import ListHead from "../../helpers/ListHead";
import SearchNotFound from "../../helpers/SearchNotFound";
import ListCtaMenu from "../../helpers/ListCtaMenu";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { MIconButton } from "../../helpers/@material-extend";

import { useBookingContext } from "../../../context/booking/bookingContext";

const TABLE_HEAD = [
  { id: "name", label: "Shift Name", alignRight: false },
  { id: "shiftTime", label: "Hours", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function SingleShiftList({ shifts, shiftPermissions }) {
  const [filterName] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isShiftNotFound = shifts.length === 0;

  const { deleteStateShift } = useBookingContext();

  const handleDeleteShift = async (shiftId) => {
    try {
      const response = await axiosPrivate.delete(`/shift/${shiftId}`, {
        headers: {
          id: `${shiftId}`,
        },
      });

      if (response?.data.code === 200) {
        enqueueSnackbar("Shift deleted successuflly", {
          variant: "success",
          action: (key) => (
            <MIconButton size='small' onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });

        deleteStateShift(shiftId);
      }

    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Card className='reservationTableWrapper'>
      <TableContainer>
        <Table>
          <ListHead headLabel={TABLE_HEAD} rowCount={shifts.length} />
          <TableBody>
            {shifts.map((row) => {
              const { id, name, time_from, time_to } = row;

              return (
                <TableRow hover key={id} tabIndex={-1} role='checkbox'>
                  <TableCell component='th' scope='row'>
                    <Stack direction='row' alignItems='center' spacing={2}>
                      <Typography variant='subtitle2' noWrap>
                        {name}
                      </Typography>
                    </Stack>
                  </TableCell>

                  <TableCell component='th' scope='row'>
                    <Stack direction='row' alignItems='center' spacing={2}>
                      <Typography variant='subtitle2' noWrap>
                        {`${time_from || ""} - ${time_to || ""}`}
                      </Typography>
                    </Stack>
                  </TableCell>

                  <TableCell align='right'>
                    <ListCtaMenu
                      id={id}
                      permissions={shiftPermissions}
                      onDelete={() => handleDeleteShift(id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          {isShiftNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Card>
  );
}
