import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useRef, useState, useEffect } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import personFill from "@iconify/icons-eva/person-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import clockFill from "@iconify/icons-eva/clock-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import bookFill from "@iconify/icons-eva/book-open-fill";
import calendarFill from "@iconify/icons-eva/calendar-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
// routes

// hooks

import useIsMountedRef from "../../hooks/useIsMountedRef";
// components

import { MIconButton } from "../helpers/@material-extend";
import MenuPopover from "../helpers/MenuPopover";
import { useAuthContext } from "../../context/auth/authContext";
import MyAvatar from "../helpers/Avatar";
import useSettings from "../../hooks/useSettings";

import Scrollbar from "../helpers/Scrollbar";
import { styled } from "@mui/system";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Grid from "@mui/material/Grid";
import usePermissions from "../../hooks/usePermssions";
import {useUtilityContext} from "../../context/utility/utilityContext";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: homeFill,
    linkTo: "/",
  },
  {
    label: "Profile",
    icon: personFill,
    linkTo: "/profile",
  },
];

const ADMIN_MENU_OPTIONS = [
  {
    label: "Services",
    icon: calendarFill,
    linkTo: "/services",
  },
  {
    label: "Working Hours",
    icon: clockFill,
    linkTo: "/shifts",
  },
  {
    label: "Users",
    icon: peopleFill,
    linkTo: "/users",
  },

  {
    label: "Groups",
    icon: bookFill,
    linkTo: "/groups",
  },
];

// ----------------------------------------------------------------------
const getValues = (settingsSrc) => ({
  franchise: settingsSrc.franchise,
});

export default function AccountPopover() {
  const { settings, saveSettings } = useSettings();
  const { user, logoutUser, updateUserFranchise } = useAuthContext();

  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [values, setValues] = useState(getValues(user));
  const [franchizes, setFranchizes] = useState();
  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const permissionsFranchise = usePermissions("franchise");
  const permissionsUser = usePermissions("user");
  const permissionsShift = usePermissions("shift");
  const permissionsGroup = usePermissions("group");
  const permissionsService = usePermissions("service");
  const permissions = {
    user: permissionsUser,
    group: permissionsGroup,
    shift: permissionsShift,
    service: permissionsService,
  };
  const noFranchizesAllowed = Object.values(permissionsFranchise).every(value => value === false);

  const getFilteredAdminMenuOptions = (permissions) => {
    const filteredOptions = ADMIN_MENU_OPTIONS.filter((option) => {
      switch (option.label) {
        case "Users":
          return permissions.user["list"];
        case "Groups":
          return permissions.group["list"];
        case "Working Hours":
          return permissions.shift["list"];
        case "Services":
          return permissions.service["list"];
        default:
          return true;
      }
    });

    return filteredOptions;
  };

  const filteredAdminMenuOptions = getFilteredAdminMenuOptions(permissions);



  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getFranchizes = async () => {
      try {
        const response = await axiosPrivate("/franchises", {
          signal: controller.signal,
        });

        isMounted && setFranchizes(response.data.data);


      } catch (error) {
        console.log(error);
      }
    };
    getFranchizes();
    saveSettings(values);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);




  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = async () => {
    user.franchise = values.franchise;
    const response = await axiosPrivate.patch("/franchise", {
      user: user.id,
      franchise: values.franchise,
    });

    if (response.data.code === 200) {
      saveSettings(values);
      updateUserFranchise(user);
      navigate("/")
    } else {
      console.log(response.data.message);
    }
  };

  const handleLogout = () => {
    try {
      axiosPrivate("/logout");
      saveSettings({ franchise: "1" });
      logoutUser();

      enqueueSnackbar("You have been logged out!", {
        variant: "success",
      });
      navigate("/");
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const CustomScrollbar = styled(
      Scrollbar,
      { }

     )({
    height:"500px",
    "@media (min-width: 575px)": {
      height:"auto",
    },

  });

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          marginLeft: 2,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <CustomScrollbar>
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant='subtitle1' noWrap>
              {user.first_name}
            </Typography>
            <Typography variant='body2' sx={{ color: "text.secondary" }} noWrap>
              {user.username}
            </Typography>
          </Box>

          <Divider sx={{ my: 1 }} />

          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: "body2", py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />

              {option.label}
            </MenuItem>
          ))}

          <Divider sx={{ my: 1 }} />
          {filteredAdminMenuOptions.length > 0 &&
              <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant='subtitle1' noWrap>
                  Admin Features
                </Typography>
              </Box>

          }

          {filteredAdminMenuOptions.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: "body2", py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />

              {option.label}
            </MenuItem>
          ))}
          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button
                fullWidth
                color='inherit'
                variant='outlined'
                onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
          {!noFranchizesAllowed &&
              <>

          <Divider sx={{ my: 1 }} />

          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant='subtitle1' noWrap>
              Choose Franchise
            </Typography>
          </Box>
          <Box sx={{ mx: 1.5, my: 3 }}>
            {franchizes && (
              <TextField
                fullWidth
                label='Franchise'
                name='franchise'
                onChange={(event) =>
                  handleChange("franchise", event.target.value)
                }
                select
                SelectProps={{ native: true }}
                value={values.franchise}
                variant='outlined'
              >
                {permissionsFranchise && franchizes.map((franchize) => {
                  if (permissionsFranchise[franchize.name]) {
                   return <option key={franchize.id} value={franchize.id}>
                      {franchize.name}
                    </option>
                  }
                })}
              </TextField>
            )}
          </Box>
          <Box sx={{ mx: 1.5, my: 2 }}>
            <Button
              color='primary'
              fullWidth
              onClick={handleSave}
              variant='contained'
            >
              Save Settings
            </Button>
          </Box>
              </>
          }
        </CustomScrollbar>
      </MenuPopover>
    </>
  );
}
