import * as Yup from "yup";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";

// material
import { LoadingButton } from "@mui/lab";

import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  FormHelperText,
  FormControlLabel,
  Button,
} from "@mui/material";
import UploadFile from "./UploadFile";
import { fData } from "../../../utlis/formatNumber";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { MIconButton } from "../../helpers/@material-extend";
import { useBookingContext } from "../../../context/booking/bookingContext";
// utils

// routes

// ----------------------------------------------------------------------

ServiceNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentService: PropTypes.object,
};

export default function ServiceNewForm({ isEdit, currentService, shifts }) {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const axiosPrivate = useAxiosPrivate();
  const [hasUserChangedImage, setHasUserChangedImage] = useState(false);

  const { addStateService } = useBookingContext();

  const NewServiceSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: currentService?.id || null,
      name: currentService?.name || "",
      image: currentService?.image || "",
      description: currentService?.description || "",
      shift: currentService?.shift || "",
      whole_shift: currentService?.whole_shift || 0,
    },
    validationSchema: NewServiceSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      if (!isEdit) {
        const createConvertedValues = {
          name: values.name,
        };
        try {
          const response = await axiosPrivate.post(
            "/service",
            createConvertedValues
          );

          if (response?.data.code === 200) {
            resetForm();
            addStateService(response.data.data);
            setSubmitting(false);
            enqueueSnackbar(
              "Service created successuflly",

              {
                variant: "success",
                action: (key) => (
                  <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </MIconButton>
                ),
              }
            );

            navigate(`/services/${response.data.data.id}/edit`);
          }
        } catch (error) {
          console.error(error);
          setSubmitting(false);
          setErrors(error);
        }
      } else {
        let newValues = values;
        if (!hasUserChangedImage) {
          newValues = {
            id: values.id,
            name: values.name,
            image: "",
            description: values.description,
            shift: values.shift,
            whole_shift: values.whole_shift,
          };
        }
        try {
          const response = await axiosPrivate.put(
            `/service/${currentService.id}`,
            newValues
          );

          if (response?.data.code === 200) {
            resetForm();
            setSubmitting(false);
            enqueueSnackbar("Service updated successfully", {
              variant: "success",
              action: (key) => (
                <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              ),
            });

            navigate(`/services`);
          }
        } catch (error) {
          console.error(error);
          setSubmitting(false);
          setErrors(error);
        }
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  const handleDropOld = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      console.log(file);
      if (file) {
        const base64 = convertBase64(file);

        setFieldValue("image", {
          ...file,
          preview: URL.createObjectURL(file),
        });
      }
    },
    [setFieldValue]
  );

  const handleDrop = async (files) => {
    const file = files[0];
    if (file) {
      const base64 = await convertBase64(file);
      setFieldValue("image", base64);
      setHasUserChangedImage(true);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {isEdit && (
            <Grid item xs={12} md={4}>
              <Card>
                <Box sx={{ mb: 5 }}>
                  <UploadFile
                    accept='.svg'
                    file={values.image}
                    maxSize={3145728}
                    onDrop={(acceptedFiles) => handleDrop(acceptedFiles)}
                    error={Boolean(touched.image && errors.image)}
                    caption={
                      <Typography
                        variant='caption'
                        sx={{
                          mt: 2,
                          mx: "auto",
                          display: "block",
                          textAlign: "center",
                          color: "text.secondary",
                        }}
                      >
                        Allowed *.svg
                        <br /> max size of {fData(3145728)}
                      </Typography>
                    }
                  />
                  <FormHelperText error sx={{ px: 2, textAlign: "center" }}>
                    {touched.image && errors.image}
                  </FormHelperText>
                </Box>
              </Card>
            </Grid>
          )}

          <Grid item xs={12} md={isEdit ? 8 : 12}>
            <Card sx={{ p: 3, mb: 10 }} className={"smallForms"}>
              {isEdit && (
                <Button
                  variant='contained'
                  component={RouterLink}
                  to={`/subservices/${currentService?.id}`}
                  sx={{ marginLeft: "auto", mb: 3 }}
                >
                  Subservices
                </Button>
              )}

              <Stack spacing={3} sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  label='Name'
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

                {isEdit && (
                  <TextField
                    {...getFieldProps("description")}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    label='Description'
                  />
                )}
              </Stack>

              {isEdit && (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                  <TextField
                    select
                    fullWidth
                    label='Shifts'
                    placeholder='Shifts'
                    {...getFieldProps("shift")}
                    SelectProps={{ native: true }}
                    error={Boolean(touched.shift && errors.shift)}
                    helperText={touched.shift && errors.shift}
                  >
                    {shifts?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>

                  <Card sx={{ p: 1, minWidth: "250px" }}>
                    <FormControlLabel
                      labelPlacement='start'
                      control={
                        <Switch
                          onChange={(event) =>
                            setFieldValue(
                              "whole_shift",
                              event.target.checked ? 1 : 0
                            )
                          }
                          checked={values.whole_shift == 1}
                        />
                      }
                      label={
                        <>
                          <Typography variant='subtitle2'>
                            Whole Shift
                          </Typography>
                        </>
                      }
                      sx={{
                        mx: 0,

                        width: 1,
                        justifyContent: "space-between",
                      }}
                    />
                  </Card>
                </Stack>
              )}
              <Stack spacing={3}>
                <Box
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                  >
                    {!isEdit ? "Create Service" : "Save Changes"}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
