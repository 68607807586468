import { Outlet } from "react-router-dom";
import LayoutWrapper from "../../assets/wrappers/LayoutWrapper";
import FloatingReservations from "../../components/dashboard/booking/FloatingReservations";
import BottomNavigationNav from "../../components/navigation/BottomNavigationNav";

import Navbar from "../../components/navigation/Navbar";

const Layout = () => {
  return (
    <LayoutWrapper>
      <div className='toolbar-height'></div>
      <Navbar />
      <FloatingReservations />
      <div className='main-content'>
        <Outlet />
      </div>

      <BottomNavigationNav />
    </LayoutWrapper>
  );
};

export default Layout;
