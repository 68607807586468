import ReservationPopover from "./ReservationPopover";
import { styled } from "@mui/system";
import {useAuthContext} from "../../../context/auth/authContext";

const StyledFloatingWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: "0 15px",
  zIndex: "999",
  position: "fixed",
  top:"80px",
  right:"0",
  minHeight: "45px",

  ".inner": {
    background:"white",
    borderBottomLeftRadius:"8px"
  },

  ".MuiButtonBase-root": {
    margin: "0 !important",
  },
  ".box": {

    cursor: "move",
    width: "40px",
    height: "40px",
    // zIndex: "999999999999",
    userSelect: "none",
    margin: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const FloatingReservations = () => {
  const { activeReservations } = useAuthContext();


  return (
    <StyledFloatingWrapper>
        <div className="inner">
      {activeReservations?.length > 0 &&
        activeReservations.map((reservation) => {
          return (
            <ReservationPopover
              key={reservation}
              reservation={reservation}
            />
          );
        })}
        </div>
    </StyledFloatingWrapper>
  );
};
export default FloatingReservations;
