
import { useState} from "react";

// material

import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,

} from "@mui/material";

import ListToolbar from "../../helpers/ListToolbar";
import ListHead from "../../helpers/ListHead";
import SearchNotFound from "../../helpers/SearchNotFound";
import ListCtaMenu from "../../helpers/ListCtaMenu";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSnackbar } from "notistack";
import { MIconButton } from "../../helpers/@material-extend";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function SingleSubServiceList({
  subServices,
  subSpermissons,
  setSubServices,
}) {
  const [filterName] = useState("");
  const axiosPrivate = useAxiosPrivate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleFilterByName = (event) => {};


  const handleDeleteSubService = async (subServiceId) => {
    try {
      const response = await axiosPrivate.delete(
        `/subservice/${subServiceId}`,
        {
          headers: {
            id: `${subServiceId}`,
          },
        }
      );

      if (response?.data.code === 200) {
        enqueueSnackbar("Subservice deleted successfully", {
          variant: "success",
          action: (key) => (
            <MIconButton size='small' onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });
        setSubServices(subServices.filter((s) => s.id !== subServiceId));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isUserNotFound = subServices.length === 0;



  return (
    <Card className='reservationTableWrapper'>
      <ListToolbar onFilterName={handleFilterByName} />

      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <ListHead headLabel={TABLE_HEAD} rowCount={subServices.length} />
          <TableBody>
            {subServices.map((row) => {
              const { id, name, qty } = row;

              return (
                <TableRow hover key={id} tabIndex={-1} role='checkbox'>
                  <TableCell component='th' scope='row'>
                    <Stack direction='row' alignItems='center' spacing={2}>
                      <Typography variant='subtitle2' noWrap>
                        {name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align='left'>{qty}</TableCell>

                  <TableCell align='right'>
                    <ListCtaMenu
                      id={id}
                      onDelete={() => handleDeleteSubService(id)}
                      permissions={subSpermissons}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          {isUserNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Card>
  );
}
