import { AppBar } from "@mui/material";

import IconButton from "@mui/material/IconButton";

import HomeIcon from "@mui/icons-material/Home";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import StyledToolbar from "../../assets/wrappers/NavigationWrapper";

import SideDrawer from "./SideDrawer";
import AccountPopover from "./AccountPopover";
import Searchbar from "../layoutHelpers/SearchBar";
import { Link as RouterLink } from "react-router-dom";
import {useBookingContext} from "../../context/booking/bookingContext";
import NotificationButton from "../layoutHelpers/NotificationButton";

const Navbar = () => {
  const {
    isGlobalSearchOpen,
    setIsGlobalSearchOpen,
  } = useBookingContext();
  return (
    <>
      <AppBar>
        <StyledToolbar>
          <div>
            <IconButton
                size='large'
                edge='start'
                color='inherit'
                className='homeIcon'
                component={RouterLink}
                to='/'
            >
              <HomeIcon sx={{ color: "white" }} fontSize='large' />
            </IconButton>

            <IconButton
                size='large'
                edge='start'
                color='inherit'
                className='homeIcon'
                component={RouterLink}
                to='create-reservation'
            >
              <AddIcon  sx={{ color: "white" }} fontSize='large' />
            </IconButton>

            <IconButton
                size='large'
                edge='start'
                color='inherit'
                className='homeIcon'
                onClick={() => setIsGlobalSearchOpen(!isGlobalSearchOpen)}
            >
              <SearchIcon sx={{ color: "white" }} fontSize='large' />
            </IconButton>
            <NotificationButton/>
          </div>
          <div>
            <Searchbar />
            <SideDrawer />
            <AccountPopover />
          </div>

        </StyledToolbar>
      </AppBar>
    </>
  );
};
export default Navbar;
