
// material
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener, IconButton,
} from "@mui/material";
import { MIconButton } from "../helpers/@material-extend";
import SearchOrdersInput from "../navigation/SearchOrdersInput";
import { useBookingContext } from "../../context/booking/bookingContext";
import CloseIcon from "@mui/icons-material/Close";
// components

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled("div")(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  alignItems: "center",
  height: APPBAR_DESKTOP,
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: "2px 9px 15px 9px rgba(0,0,0,0.17)",
  backgroundColor: `${alpha(theme.palette.background.default, 1)}`,
  [theme.breakpoints.up("md")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },

  ".inner": {
    width:"100%",
    height:"100%",
    position:"relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const { isGlobalSearchOpen, setIsGlobalSearchOpen } = useBookingContext();

  const handleOpen = () => {
    setIsGlobalSearchOpen(true);
  };

  const handleClose = () => {
    setIsGlobalSearchOpen(false);
  };

  return (
    <div>
      <Slide
        direction='down'
        in={isGlobalSearchOpen}
        mountOnEnter
        unmountOnExit
      >
        <SearchbarStyle>
          <div className="inner">
            <SearchOrdersInput />
            <IconButton onClick={handleClose} className='closeIcon'>
              <CloseIcon color='error' fontSize="large" />
            </IconButton>
          </div>

        </SearchbarStyle>
      </Slide>
    </div>
  );
}
