// import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { filter } from "lodash";

// material

import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";

import ListToolbar from "../../helpers/ListToolbar";
import ListHead from "../../helpers/ListHead";
import Scrollbar from "../../helpers/Scrollbar";
import SearchNotFound from "../../helpers/SearchNotFound";
import ListCtaMenu from "../../helpers/ListCtaMenu";
import { useBookingContext } from "../../../context/booking/bookingContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSnackbar } from "notistack";
import { MIconButton } from "../../helpers/@material-extend";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function SingleServiceList({ services, servicePermissons }) {

  const [filterName, setFilterName] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const { deleteStateService } = useBookingContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function applySortFilter(array, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    if (query) {
      return filter(
          array,
          (_service) => _service.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredServices = applySortFilter(
      services,
      filterName
  );


  const handleDeleteService = async (serviceId) => {
    try {
      const response = await axiosPrivate.delete(`/service/${serviceId}`, {
        headers: {
          id: `${serviceId}`,
        },
      });

      if (response?.data.code === 200) {
        enqueueSnackbar("Service deleted successuflly", {
          variant: "success",
          action: (key) => (
            <MIconButton size='small' onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });

        deleteStateService(serviceId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isServiceNotFound = services.length === 0;

  return (
    <Card className='reservationTableWrapper'>
      <ListToolbar onFilterName={handleFilterByName} />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ListHead headLabel={TABLE_HEAD} rowCount={services.length} />
            <TableBody>
              {filteredServices
                .map((row) => {
                  const {
                    id,
                    name,
                    image,
                    description,
                  } = row;

                  return (
                    <TableRow hover key={id} tabIndex={-1} role='checkbox'>
                      <TableCell component='th' scope='row'>
                        <Stack direction='row' alignItems='center' spacing={2}>
                          <Avatar alt={name} src={image} />
                          <Typography variant='subtitle2' noWrap>
                            {name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align='left'>{description}</TableCell>

                      <TableCell align='right'>
                        <ListCtaMenu
                          id={id}
                          onDelete={() => handleDeleteService(id)}
                          permissions={servicePermissons}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
            {isServiceNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align='center' colSpan={6} sx={{ py: 3, minHeight:"500px"}}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>


    </Card>
  );
}
