import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import SingleReservationModal from "../SingleReservationModal";
import {styled} from "@mui/system";
import {useBookingContext} from "../../../../context/booking/bookingContext";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import AlertDialog from "../../../helpers/AlertDialog";
import Button from "@mui/material/Button";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import {ModalListWrapper} from "../styles";
import moment from "moment";
import SingleReservationModalPopover from "../../../helpers/SingleReservationModalPopover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";

const StyledListItem = styled(ListItem )(({ theme }) => ({
    position:"relative",
    padding:"0",
    ".MuiButtonBase-root": {
        position: "relative",
        zIndex:"88",
       width:"100%",
       flex:1,
        borderRadius:0,
        justifyContent:"flex-start",
        padding:"15px 25px"
    },
".modalClass": {
    position:"absolute",
    right:"25px",
    top:"40%",
    transform:"translateY(-50%)"
}
}));

const useStyles = makeStyles((theme) => ({
    dialogTitleCstm: {
        fontSize:"2rem", borderBottom:"3px solid #D8D8D8", display:"flex", justifyContent:"space-between", alignItems:"center",

    },

    inner: {
        display:"flex",
        alignItems:"center",
        flexWrap:"wrap"
    },
    text: {
        paddingRight:"10px",
    },

    "@media (max-width: 768px)": {
        dialogTitleCstm: {
            fontSize:"1.5rem",

        },
    },
}));

export  default function ModalDialogPicker(props) {
    const { onClose, open, ordersProp, orderDetails, setOrderDetails } = props;
    const classes = useStyles();
    const {updateStateOrders} = useBookingContext();
    const axiosPrivate = useAxiosPrivate();

    const [orderLogs, setOrderLogs] = useState([])
    const {
        id,
        name,
        email,
        phone,
        date_created,
        created_by,
        status,
        editor_name,
        arrived_at,
        note,
        items,
    } = orderDetails


    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        onClose();
    };

    let statusText;
    switch (status) {
        case "1":
            statusText = "In progress";
            break;
        case "2":
            statusText = "Finished";
            break;

        case "4":
            statusText = "Stopped";
            break;

        default:
            statusText = "In progress";
            break;
    }


    const handlePersonArrivedClick = async () =>  {
        try {
            const response = await axiosPrivate.patch(
                `/order/arrival/${id}` );

            if (response?.data.code === 200) {
                setOrderDetails(response.data.data);
                updateStateOrders(response.data.data)
                handleClose();
            }
        } catch (error) {
            console.error(error);
        }
    }


    const handleGetLogsForOrder = async () =>  {
        try {
            const response = await axiosPrivate(
                `/order/logs/${id}` );

            if (response?.data.code === 200) {
                setOrderLogs(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, padding: "15px 60px 15px 25px" }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (
       <>
           {ordersProp?.length > 1 ?
               <Dialog onClose={handleClose} open={open}>
                   <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                       Select Order Modal
                   </BootstrapDialogTitle>

                   <List sx={{ pt: 0 }}>


                       {ordersProp.map((order) => (
                           <StyledListItem button key={order}>
                               <SingleReservationModal order={order} />
                               <ListItemText primary={`ID: ${order}`} className="modalClass"/>
                           </StyledListItem >
                       ))}



                   </List>
               </Dialog>

               :
               <Dialog
                   fullWidth={true}
                   maxWidth="md"
                   open={open}
                   onClose={handleClose}

               >
                   {Object.keys(orderDetails).length > 0 ?
                       <>
                           <DialogTitle className={classes.dialogTitleCstm} >
                               <div className={classes.inner}>
                                   <p className={classes.text}>ORDER ID: #{id}</p>

                                   <AlertDialog
                                       target={ <Button
                                           color='primary'
                                           variant='contained'
                                           disabled={arrived_at !== null ? true : false}

                                       >
                                           Person Has Arrived
                                       </Button>}
                                       onAction={handlePersonArrivedClick}
                                       isDisabled={arrived_at !== null ? true : false}

                                   />

                               </div>

                               <IconButton onClick={handleClose} className='closeIconPopover'>
                                   <CloseIcon color='error' fontSize="large"/>
                               </IconButton>

                           </DialogTitle>
                           <DialogContent>

                               <ModalListWrapper>

                                   <dl className="infoDl">
                                       <div>
                                           <dt>Name:</dt>
                                           <dd>{name || "Not entered"}</dd>
                                       </div>

                                       <div>
                                           <dt>Email:</dt>
                                           <dd>{email ? <a href={`mailto:${email}`}>{email} </a> : "Not entered" }</dd>
                                       </div>

                                       <div>
                                           <dt>Phone:</dt>
                                           <dd>{phone ? <a href={`tel:${phone}`}>{phone} </a> : "Not entered" }</dd>
                                       </div>

                                       <div>
                                           <dt>Notes:</dt>
                                           <dd>{note}</dd>
                                       </div>
                                   </dl>


                                   <dl className="statusDl">
                                       <div>
                                           <dt>Status:</dt>
                                           <dd>{statusText}</dd>
                                       </div>

                                       <div>
                                           <dt>Current Editor:</dt>
                                           <dd>{editor_name || ""}</dd>
                                       </div>

                                       <div>
                                           <dt>Created At:</dt>
                                           <dd>{moment(date_created).format("hh:mm DD.MM.YY ") }</dd>
                                       </div>

                                       <div>
                                           <dt>Created By:</dt>
                                           <dd>{created_by || ""}</dd>
                                       </div>
                                   </dl>

                                   <div className="services-wrapper">
                                       <div className="heading-banner">
                                           <span>Service</span>
                                           <div><span>From</span> <span>To</span> </div>
                                       </div>
                                       <div className="items-wrapper">
                                           {items?.map(item => {
                                               return <div className="single-item" key={item.id}>
                                                   <div className="left">
                                                       <p>{item.service_name}</p>
                                                       <span>{item.name}</span>
                                                   </div>

                                                   <div className="right">
                                                       <p>{moment(item.time_from).format("DD.MM.YY")} <span>{moment(item.time_from).format("hh:mm")}</span></p>
                                                       <p>{moment(item.time_to).format("DD.MM.YY")} <span>{moment(item.time_to).format("hh:mm")}</span></p>
                                                   </div>
                                               </div>
                                           })}

                                       </div>

                                   </div>

                                   <div className="logs-wrapper">
                                       <Button variant="contained" onClick={handleGetLogsForOrder} sx={{marginTop:"10px"}}>Get Logs</Button>
                                       {orderLogs?.length > 0 &&   <div className="heading-banner">
                                           <div>
                                               <span>Log ID</span>
                                               <span>User ID</span>
                                           </div>

                                           <div className="cta-div"><span>Date</span> <span>Action</span> </div>
                                       </div>}

                                       <div className="items-wrapper">
                                           {orderLogs?.map(item => {
                                               return <div className="single-item" key={item.id} >
                                                   <div className="left">

                                                       <p>{item.id}</p>
                                                       <span>{item.user}</span>
                                                       <SingleReservationModalPopover itemId={item.id}/>
                                                   </div>

                                                   <div className="right">
                                                       <p>{moment(item.datum).format('lll')}</p>
                                                       <p>{item.action}</p>

                                                   </div>
                                               </div>
                                           })}
                                       </div>

                                   </div>

                               </ModalListWrapper >

                           </DialogContent>
                       </>
                       :
                       <Box sx={{ display: 'flex', alignItems:"center", justifyContent:"center", minWidth:"100%", minHeight:"250px;" }}>
                           <CircularProgress />
                       </Box>
                   }

                   <DialogActions sx={{paddingBottom:"25px"}}>
                       <Button onClick={handleClose}>Close</Button>
                   </DialogActions>
               </Dialog>
           }
       </>


    );
}



