import * as Yup from "yup";
import PropTypes from "prop-types";
import { useEffect} from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {  Form, FormikProvider, useFormik } from "formik";

import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
// material
import { LoadingButton } from "@mui/lab";

import { Box, Card, Grid, Stack, TextField } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { MIconButton } from "../../helpers/@material-extend";
// utils

// routes

// ----------------------------------------------------------------------

SubServiceForm.propTypes = {
  isEdit: PropTypes.bool,
  currentSubService: PropTypes.object,
  currentService: PropTypes.object,
};

export default function SubServiceForm({
  isEdit,
  currentSubService,
  currentService,
  setCurrentSubService,
  subServiceId
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const axiosPrivate = useAxiosPrivate();

  const NewSubServiceSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });



  useEffect(() => {

    const getCurrentSubService = async () => {
      if(!currentSubService) {
        console.log("Yes")
        try {
          const response = await axiosPrivate(
              `/subservice/${subServiceId}` );

          if (response?.data.code === 200) {
            setCurrentSubService(response.data.data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    getCurrentSubService()

  }, [isEdit]);



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: currentSubService?.id,
      name: currentSubService?.name || "",
      description: currentSubService?.description || "",
    },
    validationSchema: NewSubServiceSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      if (!isEdit) {
        const createConvertedValues = {
          name: values.name,
        };
        try {
          const response = await axiosPrivate.post(
            `/subservice/${currentService.id}`,
            createConvertedValues
          );

          if (response?.data.code === 200) {
            resetForm();
            setSubmitting(false);


            enqueueSnackbar(
              "Service created successfully",

              {
                variant: "success",
                action: (key) => (
                  <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </MIconButton>
                ),
              }
            );

            navigate(`/subservices/${response.data.data.service?.id}/${response.data.data.subservice?.id}/edit`);
          }
        } catch (error) {
          console.error(error);
          setSubmitting(false);
          setErrors(error);
        }
      } else {
        try {
          const response = await axiosPrivate.put(
            `/subservice/${currentSubService.id}`,
            values
          );

          if (response?.data.code === 200) {
            resetForm();

            setSubmitting(false);
            enqueueSnackbar(
              "Subservice updated successfully",

              {
                variant: "success",
                action: (key) => (
                  <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </MIconButton>
                ),
              }
            );

            navigate(`/subservices/${currentService.id}`);
          }
        } catch (error) {
          console.error(error);
          setSubmitting(false);
          setErrors(error);
        }
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3, mb: 10 }} className={"smallForms"}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label='Name'
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

                {isEdit && (
                  <TextField
                    {...getFieldProps("description")}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    label='Description'
                  />
                )}

                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                  >
                    {!isEdit ? "Create Subservice" : "Save Changes"}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
