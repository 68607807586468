import { styled } from "@mui/system";

const LayoutWrapper = styled("div")(({ theme }) => ({
  overflow: "hidden",
  position:"relative",
  paddingTop:"25px",
  ".toolbar-height": {
    ...theme.mixins.toolbar,
    marginTop: "30px",
  },

  ".MuiBottomNavigationAction-label": {
    fontSize: "0.7rem",
    whiteSpace: "nowrap",
  },

  ".navigationPaper": {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    display: "block",
  },

  "@media (min-width: 1200px)": {
    ".navigationPaper": {
      display: "none",
    },
  },
  ".reservationTableWrapper": {
    margin: "0 0 50px 0",
    borderTop: "5px solid",
    borderColor: theme.palette.primary.main,
    ".css-1pqm26d-MuiAvatar-img": {
      objectFit: "contain",
    },

    ".text-clamped": {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      maxWidth: "200px",


    },

    ".activeArrival": {
      color:"#4BB543",
      p: {
         margin:"0 0 5px 0",
      }

    },

    ".pendingArrival": {
      color:"#ff3333",
      p:{
        margin:"5px 0",
      }

    }
  },

  ".smallForms": {
    borderTop: "5px solid",
    borderColor: theme.palette.primary.main,
  },

  ".status-chip-wrapper": {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "200px",
    maxHeight:"82px",
    overflow:"hidden",
    cursor:"pointer",
    transition:"max-height .5s linear",
    span: {
      margin: "5px",
      cursor:"pointer",
      a: {
        textDecoration: "none",
        color: "inherit",
      },
    },

    "&.expanded": {
      maxHeight: "1000px",
    }
  },

}));

export default LayoutWrapper;
