import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import roundPendingActions from "@iconify/icons-ic/round-pending-actions";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import stopCircleFill from "@iconify/icons-eva/stop-circle-fill";

// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import BookingAlertDialog from "./BookingAlertDialog";
// routes

// ----------------------------------------------------------------------

ConfirmDeletePopover.propTypes = {
  onDelete: PropTypes.func,
  onStop: PropTypes.func,
  userName: PropTypes.string,
  isStateItem: PropTypes.bool,
};

export default function ConfirmDeletePopover({
  onDelete,

}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={roundPendingActions} width={24} height={24} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 150, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >

        <BookingAlertDialog target={ <MenuItem  sx={{ color: "text.secondary"}}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={20} height={20} color='#dc143c' />
          </ListItemIcon>
          <ListItemText
              primary='Delete'
              primaryTypographyProps={{ variant: "body2" }}
              sx={{textAlign: "center"}}
          />
        </MenuItem>} onAction={onDelete} targetText={"Are you sure you want to delete?"}/>


      </Menu>
    </>
  );
}
