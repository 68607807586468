import styled from "styled-components";

export const SearchInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
   input {
     height: 50px;
     min-width: 700px;
     font-size: 1.2rem;
     padding: 5px 15px;
     @media (max-width: 992px) {
       min-width: 100%;
     }
   }

`