import {TableFiltersWrapper} from "./styles";
import {Box, FormControl, InputLabel, Select, MenuItem, TextField} from "@mui/material";
import {
    LocalizationProvider,
    MobileDatePicker,
} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import hrLocale from "date-fns/locale/hr";
import moment from "moment";
const ReservationTableFilters = ({queryValues,handleQueryChange, dateFromQuery, setDateFromQuery, dateToQuery,setDateToQuery, orderUsers,orderServices, yearQuery, setYearQuery, orderShifts}) => {


    return (
        <TableFiltersWrapper>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={hrLocale}>
            <div className="inner-wrapper">


            <Box sx={{ minWidth: 120 }} className="statusSelect">
                <FormControl fullWidth>
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                        labelId="status"
                        name="statusQuery"
                        value={queryValues.statusQuery}
                        label="Status"
                        onChange={handleQueryChange}
                    >
                        <MenuItem value={"0"}>All</MenuItem>
                        <MenuItem value={"1"}>In Progress</MenuItem>
                        <MenuItem value={"2"}>Finished</MenuItem>
                        <MenuItem value={"4"}>Stopped</MenuItem>
                    </Select>
                </FormControl>
            </Box>

                <Box sx={{ minWidth: 120 }} className="datepicker-box">
                    <MobileDatePicker
                        label='Date From'
                        className='datepicker-cstm'
                        value={dateFromQuery}
                        onChange={(newValue) => {
                            setDateFromQuery(
                                moment(newValue).format("YYYY-MM-DD")
                            );
                            // !dateToQuery &&
                            // setDateToQuery(moment(newValue).format("YYYY-MM-DD"))
                        }}

                        renderInput={(params) => <TextField {...params} />}
                    />

                </Box>

                <Box sx={{ minWidth: 120 }} className="datepicker-box">

                <MobileDatePicker
                    label='Date To'
                    className='datepicker-cstm'
                    value={dateToQuery}
                    minDate={new Date(dateFromQuery)}
                    onChange={(newValue) => {
                        setDateToQuery(
                            moment(newValue).format("YYYY-MM-DD")
                        );

                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                </Box>
                <Box sx={{ minWidth: 120 }} className="createdBySelect">
                    <FormControl fullWidth>
                        <InputLabel id="shiftSelect">Shift</InputLabel>
                        <Select
                            labelId="shiftSelect"
                            name="shiftQuery"
                            value={queryValues.shiftQuery}
                            label="Shift"
                            onChange={handleQueryChange}
                        >
                            <MenuItem  value="All">All</MenuItem>
                            {orderShifts?.map(shift => {
                                return <MenuItem key={shift.id} value={shift.id}>{shift.name}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Box>
            </div>
                <div className="inner-wrapper">


                <Box sx={{ minWidth: 120 }} className="createdBySelect">
                    <FormControl fullWidth>
                        <InputLabel id="createdBy">Created By</InputLabel>
                        <Select
                            labelId="createdBy"
                            name="createdByQuery"
                            value={queryValues.createdByQuery}
                            label="Created By"
                            onChange={handleQueryChange}
                        >
                            <MenuItem  value="All">All</MenuItem>
                            {orderUsers?.map(user => {
                               return <MenuItem key={user.id} value={user.username}>{user.first_name + " " + user.last_name}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ minWidth: 120 }} className="currentEditorSelect">
                    <FormControl fullWidth>
                        <InputLabel id="currentEditor">Current Editor</InputLabel>
                        <Select
                            labelId="currentEditor"
                            name="currentEditorQuery"
                            value={queryValues.currentEditorQuery}
                            label="Current Editor"
                            onChange={handleQueryChange}
                        >
                            <MenuItem  value="All">All</MenuItem>
                            {orderUsers?.map(user => {
                               return <MenuItem key={user.id} value={user.id}>{user.first_name + " " + user.last_name}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ minWidth: 120 }} className="serviceSelect">
                    <FormControl fullWidth>
                        <InputLabel id="serviceQuery">Service</InputLabel>
                        <Select
                            labelId="serviceQuery"
                            name="serviceQuery"
                            value={queryValues.serviceQuery}
                            label="Service"
                            onChange={handleQueryChange}
                        >
                            <MenuItem  value="All">All</MenuItem>
                            {orderServices?.map(service => {
                               return <MenuItem key={service.id} value={service.id}>{service.name}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ minWidth: 120 }} className="yearDatepicker">
                <MobileDatePicker
                    label='Year'
                    views={['year']}
                    className='datepicker-year'
                    value={yearQuery}
                    onChange={(newValue) => {
                        setYearQuery(
                            moment(newValue).format("YYYY")
                        );
                    }}

                    renderInput={(params) => <TextField {...params} />}
                />
                </Box>
                </div>
            </LocalizationProvider>
        </TableFiltersWrapper>
    );
};

export default ReservationTableFilters;
