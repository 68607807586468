// hooks
//
import { MAvatar } from "./@material-extend";
import { useAuthContext } from "../../context/auth/authContext";
import createAvatar from "../../hooks/createAvatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuthContext();

  const displayName = user.first_name + " " + user.last_name;

  return (
    <MAvatar
      src={user.photoURL}
      alt={displayName}
      color={user.photoURL ? "default" : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </MAvatar>
  );
}
