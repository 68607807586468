import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import calendarFill from "@iconify/icons-eva/calendar-fill";
// material
import { alpha } from "@mui/material/styles";
import {
  Button,
  IconButton,
  Typography,
  Box,
  Badge, Checkbox, FormControlLabel,

} from "@mui/material";
import {Link as RouterLink, useLocation, useNavigate, useParams} from "react-router-dom";
// hooks
import moment from "moment";
import "moment/locale/hr";
// components

import {  MdEditCalendar } from "react-icons/md";
import ActiveReservatonWrapper, {BottomPopoverCta} from "./styles";
import MenuPopover from "../../helpers/MenuPopover";
import Scrollbar from "../../helpers/Scrollbar";
import { MIconButton } from "../../helpers/@material-extend";
import AlertDialog from "../../helpers/AlertDialog";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useBookingContext } from "../../../context/booking/bookingContext";
import { useAuthContext } from "../../../context/auth/authContext";
import FormRow from "../../helpers/FormRow";
import FormRowTextarea from "../../helpers/FormRowTextarea";
import CloseIcon from "@mui/icons-material/Close";
import {useSnackbar} from "notistack";
import * as React from "react";
import splitByDot from "../../../utlis/splitString";
import closeFill from "@iconify/icons-eva/close-fill";
// ----------------------------------------------------------------------


const PADDING_ITEM = 2.5;

export default function ReservationPopover({

  reservation,
}) {
  const {
    setCurrentlyEditedReservation,
    activeReservationColor,
    setActiveReservationColor,
    currentlyEditedReservation,
    setReservationStop,
    deleteSelectedTimeDuration,
    handleActiveReservationItemsDelete,
    setInitalTimeDurations,
    openedPopoverReservation,
    setOpenedPopoverReservation,
    setDynamicVariableForItemsInOrderChange

  } = useBookingContext();

  const {     handleActiveReservationListDelete  } = useAuthContext()
  const isOpened = openedPopoverReservation === reservation
  const anchorRef = useRef(null);
  const [isLoading, setIsLoading] = useState();
  const [open, setOpen] = useState(false);
  const [countryCode, setCountryCode] = useState(null);
  //reservation objekt ti treba da bi inicijalno prikazao stanja na floatingu kada user nije jos kliknuo niti na jedan, u local storage su info o aktivnim rezervacijama
  const [reservationObject, setReservationObject] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    note: "",
    notification: 0,
  });
  const axiosPrivate = useAxiosPrivate();
  const { itemIdentification } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isUserOnCreateResPage = pathname.includes("create-reservation");
  const [sendNotification, setSendNotification] = useState(false)

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const getOrders = async () => {
      try {
        const response = await axiosPrivate(`/order/${reservation}`);

        if (response.data.code === 200 && isMounted) {
          setReservationObject(response.data.data);


          const phoneResult = splitByDot(response.data.data?.phone);

          setFormValues({
            name: reservationObject?.name || "",
            email: reservationObject?.email || "",
            phone: phoneResult.afterDot || "",
            note: reservationObject?.note || "",
          });
          setCountryCode(phoneResult?.beforeDot)
        } else {
          setReservationObject({});
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getOrders();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleCheckboxChange = (event) => {
    setSendNotification(event.target.checked);
  };




  const handlePutOrder = () => {
    let phoneVal = countryCode ? countryCode + "." : ""
    const url = sendNotification === true ? `/order/${currentlyEditedReservation.id}/?send_notification=1` : `/order/${currentlyEditedReservation.id}`;
    axiosPrivate
        .put(
            url,
            {...currentlyEditedReservation, name:formValues.name, email:formValues.email, phone:phoneVal + formValues.phone,note:formValues.note}
        )
        .then((response) => {
          if (response.data.code === 200) {
            setReservationObject(response.data.data.order);
            setCurrentlyEditedReservation(response.data.data.order);
            setDynamicVariableForItemsInOrderChange(moment(new Date).format("X"))
            setSendNotification(false);
          }
      });

  }


  const handleOpen = async () => {

    try {
      const response = await axiosPrivate.patch(`/order/editor/${reservation}`);

      if (response?.data.code === 200) {
        if(isUserOnCreateResPage && reservation !== currentlyEditedReservation.id) {
          setInitalTimeDurations();
          setReservationObject(response.data.data);
          setCurrentlyEditedReservation(response.data.data);
          setActiveReservationColor(response.data.data.id);
          navigate(`/create-reservation/edit/${response.data.data.id}/${itemIdentification ? itemIdentification : ""}`);
        } else if(!isUserOnCreateResPage){
          setInitalTimeDurations();
          setReservationObject(response.data.data);
          setCurrentlyEditedReservation(response.data.data);
          setActiveReservationColor(response.data.data.id);
        }
        const phoneResult = splitByDot(response.data.data?.phone);
        setFormValues({
          name: reservationObject?.name || "",
          email: reservationObject?.email || "",
          phone: phoneResult.afterDot || "",
          note: reservationObject?.note || "",

        });
        setCountryCode(phoneResult?.beforeDot)
        setOpen(true)

      }


    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    const errors = validateForm(countryCode, formValues)
    if (Object.keys(errors).length === 0) {
      setOpen(false);
      setOpenedPopoverReservation("")
      handlePutOrder();
    } else {
      enqueueSnackbar(
          errors.phone || errors.countryCode,
          {
            variant: "error",
            action: (key) => (
                <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill}/>
                </MIconButton>
            ),
          }
      );
    }
  };


  function validateForm(countryCode, formValues) {
    const errors = {};

    if (countryCode) {
      if (!formValues.phone || formValues.phone.trim() === "") {
        errors.phone = "Phone number is required when a country code is selected.";
      }
    } else {
      if (formValues.phone && formValues.phone.trim() !== "") {
        errors.countryCode = "Country code is required when a phone number is entered.";
      }
    }

    return errors;
  }


  const handleFormChange = (e) => {

      setFormValues({ ...formValues, [e.target.name]: e.target.value });

  };


  const handleCountryCodeSelect = (val) => {
    setCountryCode(val)
  };

  const handleReservationFinish = async () => {
    let phoneVal = countryCode ? countryCode + "." : ""
    const url = sendNotification === true ? `/order/${currentlyEditedReservation.id}/?send_notification=1` : `/order/${currentlyEditedReservation.id}`;
    const finishedReservation = {
      ...currentlyEditedReservation,
      status: 2,
      name:formValues.name, email:formValues.email, phone:phoneVal + formValues.phone,note:formValues.note
    };
    const errors = validateForm(countryCode, formValues)
    if (Object.keys(errors).length === 0) {
    try {
      const response = await axiosPrivate.put(
          url,
        finishedReservation
      );

      if (response?.data.code === 200) {
        handleActiveReservationListDelete(response.data.data.order.id);
        setCurrentlyEditedReservation({ status: 1, items: [] });
        setDynamicVariableForItemsInOrderChange(moment(new Date).format("X"))

        isUserOnCreateResPage &&
        navigate(`/create-reservation`);
        enqueueSnackbar(
            "Success! Reservation is done!",

            {
              variant: "success",
            }
        );
        setSendNotification(false);
      }
    } catch (error) {
      console.error(error);
    }
    } else  {
      enqueueSnackbar(
          errors.phone || errors.countryCode,
          {
            variant: "error",
            action: (key) => (
                <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill}/>
                </MIconButton>
            ),
          }
      );
    }
  };

  const handleReservationStop = async () => {
   const tempStopRangeIds = [...currentlyEditedReservation.items.map((item) => item.id)]
    try {
      const response = await axiosPrivate.patch(
        `/order/stop/${currentlyEditedReservation.id}`,
          tempStopRangeIds
      );

      if (response?.data.code === 200) {
        setInitalTimeDurations();
        setCurrentlyEditedReservation({ status: 1, items: [] });
        setReservationStop(tempStopRangeIds[0]);
        handleActiveReservationListDelete(currentlyEditedReservation.id);
        isUserOnCreateResPage &&
        navigate(`/create-reservation`);
        enqueueSnackbar(
            "Success! Reservation has been stopped.",

            {
              variant: "success",
            }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditSubserviceClick = (item) => {
    navigate(`/create-reservation/edit/${item.order}/${item.id}`);
  };



  const handleDeleteSubserviceClick = async (timerangeid) => {

      deleteSelectedTimeDuration(timerangeid);
      handleActiveReservationItemsDelete(timerangeid);


  };

  const handleAddItemToReservation = () => {
    handleOpen();
    navigate(`/create-reservation/edit/${reservation}`)
  }




  return (
    <>
      <MIconButton
        ref={anchorRef}
        size='large'
        color={activeReservationColor === reservation ? "primary" : "default"}
        onClick={() => handleOpen(true)}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.secondary.main,
                theme.palette.action.focusOpacity
              ),
          }),
          marginRight: "15px",
        }}
      >
        {reservationObject?.note ? (
          <Badge variant='dot' color='primary'>
            <Icon icon={calendarFill} width={30} height={30} />
          </Badge>
        ) : (
          <Icon icon={calendarFill} width={30} height={30} />
        )}
      </MIconButton>

      <MenuPopover
        open={open || isOpened}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant='h6' sx={{ p: PADDING_ITEM }}>
            <Typography component='span'>Reservation</Typography>
            {` #${reservationObject?.id || "..."}`}
          </Typography>
          <IconButton onClick={handleClose} className='closeIconPopover'>
            <CloseIcon color='error' />
          </IconButton>
        </Box>

        <Scrollbar sx={{ height: 350 }}>
          <ActiveReservatonWrapper>
            <form className='author-box'>
              <FormRow
                type='text'
                name='name'
                value={isLoading ? "..." : formValues.name}
                handleFormChange={handleFormChange}
              />
              <FormRow
                type='text'
                name='email'
                value={isLoading ? "..." : formValues.email}
                handleFormChange={handleFormChange}
              />

              <FormRow
                type='text'
                name='phone'
                value={isLoading ? "..." : formValues.phone}
                handleFormChange={handleFormChange}
                handleCountryCodeSelect={handleCountryCodeSelect}
                countryCode={countryCode}
              />

              <FormRowTextarea
                name='note'
                value={isLoading ? "..." : formValues.note}
                handleFormChange={handleFormChange}
              />
              <FormControlLabel
                  label="Send Notification"
                  control={<Checkbox  checked={sendNotification}  onChange={handleCheckboxChange} disabled={formValues.phone === ""}/>}
              />
            </form>

            <div className='services-box'>
              {currentlyEditedReservation?.items?.map((item) => {
                return (
                  <div className='single-service' key={item.id}>
                    <p>
                      {item.name}
                      <span>
                        {`${moment(item.time_from)
                          .locale("hr")
                            .format("lll")} - ${moment(item.time_to)
                          .locale("hr")
                            .format("lll")}`}
                      </span>
                    </p>

                    <div className='icons'>
                      <IconButton
                        size='large'
                        edge='start'
                        color='default'
                        className='homeIcon'
                        onClick={() => handleEditSubserviceClick(item)}
                      >
                        <MdEditCalendar fontSize='large' />
                      </IconButton>

                      <AlertDialog
                          onAction={() =>
                          handleDeleteSubserviceClick(item.id)
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>

          </ActiveReservatonWrapper>
        </Scrollbar>
        <BottomPopoverCta>
          <Button
              color='success'
              variant='outlined'
              onClick={handleAddItemToReservation}
          >
            Add Item
          </Button>

          <AlertDialog
              target={  <Button  color='error'variant='outlined'>
                Stop
              </Button>}
              onAction={handleReservationStop}
          />




          <Button
              color='success'
              variant='contained'
              onClick={handleReservationFinish}
          >
            Finish
          </Button>
        </BottomPopoverCta>
      </MenuPopover>
    </>
  );
}
