import { createContext} from "react";
import PropTypes from "prop-types";
import { THEMES } from "../../constants";
import useLocalStorage from "../../hooks/useLocalStorage";

const initialSettings = {
  franchise: THEMES.RESTAURANT,
};

export const storeSettings = (settings) => {
  window.localStorage.setItem("cpd_settings", JSON.stringify(settings));
};

const SettingsContext = createContext({
  settings: initialSettings,
  saveSettings: () => {},
});

export const SettingsProvider = (props) => {
  const { children } = props;
  const [settings, setSettings] = useLocalStorage(
    "cpd_settings",
    initialSettings
  );

  const saveSettings = (updatedSettings) => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
