import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";

const ReservationPageWrapper = styled(Grid)(({ theme }) => ({
  maxWidth: "1200px",
  margin: "0 auto",
  padding: "25px 5px 15px 5px",

  "@media (min-width: 1650px)": {
    padding: "50px",
  },

  ".MuiLink-root": {
    textDecoration: "none",
  },

  ".customToggleButtoNGroup .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    margin: "5px",
  },
  ".customToggleButtoNGroup .MuiToggleButtonGroup-grouped": {
    borderRadius: "4px !important",
    margin: "5px ",
  },
  ".css-1l8zqf-MuiButtonBase-root-MuiToggleButton-root.Mui-selected ": {
    color: "white",
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  ".customToggleButtoNGroup": {
    flexWrap: "wrap",
  },
  ".first-grid": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "wrap",
  },

  ".css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
    margin: "0 5px 0 5px",
  },

  "@media (max-width: 575px)": {
    ".first-grid": {
      flexDirection: "column",
    },

    ".css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
      margin: "0 5px 15px 5px",
    },
  },


}));

export default ReservationPageWrapper;
