import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { styled } from "@mui/system";
import {
  MdDashboard,
  MdEditCalendar,
  MdDateRange,
  MdAppRegistration,
  MdOutlineAvTimer,
  MdOutlinePeopleAlt,
  MdAdminPanelSettings,
} from "react-icons/md";

import { useLocation, useNavigate } from "react-router-dom";
const MainDrawerWrapper = styled(Drawer)(({ theme }) => ({
  display: "none",
  "@media (min-width: 1200px)": {
    display: "block",
  },
  ".MuiPaper-root": {
    width: "300px",
  },

  ".MuiList-root": {
    padding: 0,
  },

  ".MuiListItemIcon-root": {
    minWidth: 30,
    svg: {
      width: 20,
      height: 20,
    },
  },

  ".activeLink": {
    background: theme.palette.primary.main,
    color: "#FFF",
    ".MuiListItemIcon-root": {
      svg: {
        color: "#FFF",
      },
    },

    ":hover": {
      background: theme.palette.primary.main,
    },
  },
}));

const adminMenuItems = [
  {
    text: "Services",
    icon: <MdAppRegistration />,
    path: "/services",
  },
  {
    text: "Working Hours",
    icon: <MdOutlineAvTimer />,
    path: "/shifts",
  },
  {
    text: "Users",
    icon: <MdOutlinePeopleAlt />,
    path: "/users",
  },

  {
    text: "Groups",
    icon: <MdAdminPanelSettings />,
    path: "/groups",
  },
];

const menuItems = [
  {
    text: "Dashboard",
    icon: <MdDashboard />,
    path: "/",
  },


  {
    text: "Add Reservation",
    icon: <MdEditCalendar />,
    path: "/create-reservation",
  },
];

const SideDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <IconButton
        size='large'
        edge='start'
        color='inherit'
        aria-label='open drawer'
        onClick={() => setIsDrawerOpen(true)}
        className={"drawerIcon"}
      >
        <MenuIcon sx={{ color: "white" }} fontSize='large' />
      </IconButton>

      <MainDrawerWrapper
        anchor='right'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        className='mainDrawer'
      >
        <>
          <Box sx={{ my: 2, px: 2.5 }}>
            <Typography variant='h5' noWrap>
              Main Menu
            </Typography>
          </Box>
          <Divider />

          <List>
            {menuItems.map((item) => (
              <ListItemButton
                key={item.text}
                onClick={() => {
                  navigate(item.path);
                  setIsDrawerOpen(false);
                }}
                className={location.pathname == item.path ? "activeLink" : ""}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            ))}
          </List>
          <Divider />
          <Box sx={{ my: 2, px: 2.5 }}>
            <Typography variant='h5' noWrap>
              Admin Features
            </Typography>
          </Box>
          <Divider />

          <List>
            {adminMenuItems.map((item) => (
              <ListItemButton
                key={item.text}
                onClick={() => {
                  navigate(item.path);
                  setIsDrawerOpen(false);
                }}
                className={location.pathname == item.path ? "activeLink" : ""}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            ))}
          </List>
        </>
      </MainDrawerWrapper>
    </>
  );
};
export default SideDrawer;
