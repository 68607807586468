import {
  ADD_USER,
  CLEAR_ALERT,
  DELETE_USER,
  DISPLAY_ALERT,
  GET_GROUPS,
  GET_USERS,
  HANDLE_ACTIVE_RESERVATION_LIST_CHANGE, HANDLE_ACTIVE_RESERVATION_LIST_DELETE,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGENERATE_ACCESS_TOKEN, SET_ROWS_PER_PAGE,
  UPDATE_USER_FRANCHISE,
} from "../actions";

const authReducer = (state, { type, payload }) => {
  switch (type) {
    case DISPLAY_ALERT:
      return {
        ...state,
        showAlert: true,
        alertType: payload.alertType,
        alertText: payload.alertText,
      };

      case SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage:payload.value,
      };

    case CLEAR_ALERT:
      return {
        ...state,
        showAlert: false,
        alertType: "",
        alertText: "",
      };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: payload.user,
        roles: payload.roles,
        accessToken: payload.access_token,
        refreshToken: payload.refresh_token,
        expire: payload.expire,
        showAlert: true,
        alertType: "success",
        alertText: payload.alertText,
        permissions: payload.permissions,

      };

    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        accessToken: "",
        refreshToken: "",
        roles: [],
        activeReservations:[],
      };

    case REGENERATE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload.newAccessToken,
        refreshToken: payload.newRefreshToken,
      };

    case GET_USERS:
      return {
        ...state,
        users: payload.users,
      };

    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== payload.userId),
      };

    case ADD_USER:
      return {
        ...state,
        users: [...state.users, payload.user],
      };

    case UPDATE_USER_FRANCHISE:
      return {
        ...state,
        activeReservations:[],
        user: payload.user,
        example:!state.example,
      };

    case GET_GROUPS:
      return {
        ...state,
        groups: payload.groups,
      };

    case HANDLE_ACTIVE_RESERVATION_LIST_CHANGE:

      const index = state.activeReservations.indexOf(payload.reservationId)

      if(index !== -1) {
        return {
          ...state,
          activeReservations: [...state.activeReservations]
        }
      } else {
        return {
          ...state,
          activeReservations: [...state.activeReservations, payload.reservationId]

        }
      }

    case HANDLE_ACTIVE_RESERVATION_LIST_DELETE:
      return {
        ...state,
        activeReservations: [
          ...state.activeReservations.filter(
              (res) => res !== payload.reservationId
          ),
        ],
      };
    default:
      return state;
  }
};

export default authReducer;
