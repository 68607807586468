const FormRowTextarea = ({ name, value, handleFormChange, labelText }) => {
  return (
    <div className='form-row'>
      <label htmlFor={name} className='form-label'>
        {labelText || name}
      </label>
      <textarea
        value={value}
        name={name}
        onChange={handleFormChange}
        className='form-textarea'
      />
    </div>
  );
};
export default FormRowTextarea;
