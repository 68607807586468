import { Grid } from "@mui/material";
import { paramCase, capitalCase } from "change-case";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import HeaderBreadcrumbs from "../../components/layoutHelpers/HeaderBreadcrumbs";
import { useBookingContext } from "../../context/booking/bookingContext";

import { useParams, useLocation } from "react-router-dom";
import ServiceNewForm from "../../components/dashboard/services/ServiceNewForm";
import { useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const AddService = () => {
  const {
    services,
    shifts,
    setStateServices,
    setStateShifts,
  } = useBookingContext();
  const { pathname } = useLocation();
  const { id } = useParams();
  const isEdit = pathname.includes("edit");
  const currentService = services?.find((service) => service.id === id);
  const axiosPrivate = useAxiosPrivate();

  const SERVICES_URL = "/services";
  const SHIFTS_URL = "/shifts";

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getServicesAndShifts = async () => {
      try {
        axiosPrivate(SHIFTS_URL, {
          signal: controller.signal,
        })
          .then((response) => {
            if (response.data.code === 200 && isMounted) {
              isMounted && setStateShifts(response.data.data || []);
            }
            return axiosPrivate(SERVICES_URL, {
              signal: controller.signal,
            });
          })
          .then((response) => {
            if (response.data.code === 200 && isMounted) {
              isMounted && setStateServices(response.data.data || []);
            }
          });
      } catch (error) {
        console.log(error);
      }
    };

    getServicesAndShifts();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <GridPageWrapper>
      <Grid item xs={12}>
        <HeaderBreadcrumbs
          heading={
            isEdit ? `Edit Service - ${currentService?.name}` : "Add Service"
          }
          links={[
            { name: "Dashboard", href: "/" },
            { name: "Services List", href: "/services" },
            { name: !isEdit ? "New Service" : "Edit Service" },
          ]}
        />
      </Grid>

      <ServiceNewForm
        isEdit={isEdit}
        currentService={currentService}
        shifts={shifts}
      />
    </GridPageWrapper>
  );
};
export default AddService;
