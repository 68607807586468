import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
// material
import {

  IconButton,

} from "@mui/material";
import ReservationsAlertDialog from "./ReservationsAlertDialog";
import {useAuthContext} from "../../context/auth/authContext";
// routes

// ----------------------------------------------------------------------

ReservationCtaMenu.propTypes = {
  onDelete: PropTypes.func,
  id: PropTypes.string,
  permissions: PropTypes.object,
};

const ReservationCtaWrapper = styled(
  "div",
  {}
)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
});

export default function ReservationCtaMenu({
  current_editor,
  id,
  order_status
}) {
  const {user} = useAuthContext();

  return (
    <ReservationCtaWrapper>
      {current_editor && current_editor !== user.id ? (
        <ReservationsAlertDialog id={id} />
      ) : (
        <IconButton
          component={RouterLink}
          to={`/create-reservation/edit/${id}`}
        >
          {order_status !== "4" &&   <EditIcon />}

        </IconButton>
      )}
    </ReservationCtaWrapper>
  );
}
