import { useAuthContext } from "../../context/auth/authContext";
import Alert from "@mui/material/Alert";

const CustomAlert = () => {
  const { alertType, alertText } = useAuthContext();

  return <Alert severity={alertType}>{alertText}</Alert>;
};

export default CustomAlert;
