import PropTypes from "prop-types";
import { Icon, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { styled } from "@mui/system";

AlertDialog.propTypes = {
  onDelete: PropTypes.func,
};

export default function AlertDialog({ target, onAction, isDisabled=false}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    !isDisabled && setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAndDelete = () => {
    onAction();
    setOpen(false);
  };

  const AlertDialogWrapper = styled(
    "div",
    {}
  )({
    ".MuiMenuItem-root": {
      justifyContent: "flex-end",
      width: "auto",
    },
    ".MuiListItemIcon-root": {
      minWidth: "auto !important",
      marginRight: 0,
      svg: {
        width: 24,
        height: 24,
        color: "crimson",
      },
    },
  });

  return (
    <AlertDialogWrapper>
      {!target ?
      <MenuItem onClick={handleClickOpen} sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <MdDeleteForever style={{width:"20px", height:"20px", borderRadius:"50%"}}/>
            </ListItemIcon>
      </MenuItem>
            :
         <div onClick={handleClickOpen}>
           {target}
         </div>

        }

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>

       Are you sure ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            This cannot be undone!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='contained'>
            Go Back
          </Button>
          <Button
            onClick={handleCloseAndDelete}
            color='error'
            variant='contained'
          >
            Confirm

          </Button>
        </DialogActions>
      </Dialog>
    </AlertDialogWrapper>
  );
}
