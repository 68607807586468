import { Button, Grid, Typography } from "@mui/material";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import CtaBox, {ReservationTitleBox} from "./styles";
import { MdDateRange, MdSearch } from "react-icons/md";
import SingleReservationList from "../../components/dashboard/booking/SingleReservationList";
import { Link as RouterLink } from "react-router-dom";
import { useBookingContext } from "../../context/booking/bookingContext";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useAuthContext} from "../../context/auth/authContext";
import ReservationTableFilters from "../../components/dashboard/booking/ReservationTableFilters";
import moment from "moment";
import useSettings from "../../hooks/useSettings";
import ReservationTableFiltersExtra from "../../components/dashboard/booking/ReservationTableFiltersExtra";
import {useUtilityContext} from "../../context/utility/utilityContext";


const initialQueryState = {
  statusQuery: '0',
  createdByQuery:"All",
  currentEditorQuery:"All",
  serviceQuery:"All",
  shiftQuery:"All",
  arrived:'All',
  approved:'All',
  sort:'1',

}
const ReservationLists = () => {
  const {
    isGlobalSearchOpen,
    setIsGlobalSearchOpen,
    setStateOrders,
    orders,
    currentlyEditedReservation,
    searchTerm
  } = useBookingContext();

  const {rowsPerPage} = useAuthContext();
  const {showExtraFilters, setShowExtraFilters,showNotApproved, handleQueryValueChange} = useUtilityContext();
  const [page, setPage] = useState(0);
  const [totalOrdersNum, setTotalOrdersNum] = useState(5);
  const [orderUsers, setOrderUsers] = useState([])
  const [orderServices, setOrderServices] = useState([])
  const [orderShifts, setOrderShifts] = useState([])
  const axiosPrivate = useAxiosPrivate();
  const [queryValues, setQueryValues] = useState(initialQueryState)
  const [dateFromQuery, setDateFromQuery] = useState(null)
  const [dateToQuery, setDateToQuery] = useState(null)
  const [yearQuery, setYearQuery] = useState(  moment(new Date).format("YYYY"))
  const [arrivedState, setArrivedState] = useState(false)



  const { settings } = useSettings();

  const handleQueryChange = (e) => {
       setQueryValues({ ...queryValues, [e.target.name]: e.target.value })
       setPage(0);

  }

  useEffect(() => {
    if(showNotApproved) {
      handleQueryValueChange(setQueryValues)
    }

  },[showNotApproved])



  const handleFilterReset = () => {
    setQueryValues(initialQueryState)
    setDateFromQuery(null)
    setDateToQuery(null)
    setYearQuery(moment(new Date).format("YYYY"))
    setPage(0);
  }
  const downloadFile = (data) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "cpd_orders.csv")
    document.body.appendChild(link)
    link.click()
  }

  const buildURL = (baseURL, params) => {
    const urlParams = new URLSearchParams(params);
    return `${baseURL}&${urlParams}`;
  };


  const constructParams = (queryValues, others = {}) => {
    const { statusQuery, createdByQuery, currentEditorQuery, serviceQuery, shiftQuery, arrived, approved } = queryValues;
    const { searchTerm, dateFromQuery, dateToQuery } = others;

    const params = {
      ...(searchTerm && { term: searchTerm }),
      ...(statusQuery !== "0" && { status: statusQuery }),
      ...(dateFromQuery && { date_from: dateFromQuery }),
      ...(dateToQuery && { date_to: dateToQuery }),
      ...(createdByQuery !== "All" && { created_by: createdByQuery }),
      ...(currentEditorQuery !== "All" && { current_editor: currentEditorQuery }),
      ...(serviceQuery !== "All" && { service: serviceQuery }),
      ...(shiftQuery !== "All" && { shift: shiftQuery }),
      ...(arrived !== "All" && { arrived }),
      ...(approved !== "All" && { approved })
    };

    return params;
  };



  useEffect(() => {

    let isMounted = true;
    const controller = new AbortController();

    const getOrders = async () => {
      const baseURL = `/orders?per_page=${rowsPerPage}&page=${page  * rowsPerPage + 1}&year=${yearQuery}&sort=${queryValues.sort}`;
      const url = buildURL(baseURL, constructParams(queryValues, { searchTerm, dateFromQuery, dateToQuery }));


      try {
        const response = await axiosPrivate(url, {
          signal: controller.signal,
        });

        if (response.data.code === 200 && isMounted) {
          setStateOrders(response.data.data.orders || []);
          setOrderUsers(response.data.data.users || []);
          setOrderServices(response.data.data.services || []);
          setOrderShifts(response.data.data.shifts || []);
          setTotalOrdersNum(parseInt(response.data.data.total) || 0)

        }


      if(response.data.code === 320 && isMounted) {
        setStateOrders([]);
      }
      } catch (error) {
        console.log(error);
      }
    };
    getOrders();


    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [page,searchTerm, rowsPerPage, currentlyEditedReservation?.id, queryValues.statusQuery,  queryValues.createdByQuery, queryValues.currentEditorQuery, queryValues.serviceQuery,queryValues.arrived, queryValues.sort, dateFromQuery, dateToQuery, yearQuery, settings.franchise,queryValues.shiftQuery, queryValues.approved, arrivedState ]);



  const handleExport = async (value) => {
    const baseURL = `/orders?export=${value}`;
    const url = buildURL(baseURL, constructParams(queryValues, { searchTerm, dateFromQuery, dateToQuery }));


    const response = await axiosPrivate(url);
    if(response.data) {
      downloadFile(response.data)
    }

  }


  return (
    <GridPageWrapper container>
      <Grid item xs={12}>
        <CtaBox>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            size='large'
            startIcon={<MdDateRange />}
            component={RouterLink}
            to={"create-reservation"}
          >
            Create New Reservation
          </Button>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            size='large'
            startIcon={<MdSearch />}
            onClick={() => setIsGlobalSearchOpen(!isGlobalSearchOpen)}
          >
            Search
          </Button>
        </CtaBox>
      </Grid>

      <Grid item xs={12}>
        <ReservationTitleBox>
          <Typography variant='h5'>
            Last Reservations
          </Typography>
          <div className="cta-buttons">
            <Button
                variant='contained'
                color='error'
                size='large'
                onClick={() => handleFilterReset()}
            >
              Reset Filters
            </Button>

            <Button
                variant='contained'
                color='primary'
                size='large'
                onClick={() => setShowExtraFilters(!showExtraFilters)}
            >
              {!showExtraFilters ? "More Filters" : "Less Filters"}
            </Button>

            <Button
                variant='contained'
                color='primary'
                size='large'
                onClick={() => handleExport(1)}
            >
              Basic Export
            </Button>

            <Button
                variant='contained'
                color='primary'
                size='large'
                onClick={() => handleExport(2)}
            >
              Extended Export
            </Button>

          </div>

        </ReservationTitleBox>

       <ReservationTableFilters setQueryValues={setQueryValues} queryValues={queryValues} handleQueryChange={handleQueryChange} setDateFromQuery={setDateFromQuery} dateFromQuery={dateFromQuery} setDateToQuery={setDateToQuery} dateToQuery={dateToQuery} orderUsers={orderUsers} orderServices={orderServices} setYearQuery={setYearQuery} yearQuery={yearQuery} orderShifts={orderShifts}/>

        {showExtraFilters && <ReservationTableFiltersExtra handleQueryChange={handleQueryChange} queryValues={queryValues}/> }

        {orders.length > 0 ?
          <SingleReservationList
            orders={orders}
            page={page}
            setPage={setPage}
            totalOrdersNum={totalOrdersNum}
            setArrivedState={setArrivedState}
          />

            :

            <h2>No orders found...</h2>
        }
      </Grid>
    </GridPageWrapper>
  );
};
export default ReservationLists;
