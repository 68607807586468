import * as Yup from "yup";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {  Form, FormikProvider, useFormik } from "formik";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
// material
import { LoadingButton } from "@mui/lab";

import {
  Box,
  Card,
  Grid,
  Stack,

  TextField,

} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { MIconButton } from "../../helpers/@material-extend";
import { useBookingContext } from "../../../context/booking/bookingContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// utils

// routes

// ----------------------------------------------------------------------

ShiftNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentShift: PropTypes.object,
};

export default function ShiftNewForm({ isEdit, currentShift, shifts }) {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const axiosPrivate = useAxiosPrivate();

  const { addStateShift } = useBookingContext();

  const timeFrom = `Wed Apr 27 2022 ${currentShift?.time_from} GMT+0200 (Central European Summer Time)`;
  const timeTo = `Wed Apr 27 2022 ${currentShift?.time_to} GMT+0200 (Central European Summer Time)`;

  const NewShiftSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: currentShift?.id || null,
      name: currentShift?.name || "",
      time_from: timeFrom || "",
      time_to: timeTo || "",
    },
    validationSchema: NewShiftSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      const convertedValues = {
        id: values.id,
        name: values.name,
        time_from: `${new Date(values.time_from).getHours()}:${new Date(
          values.time_from
        ).getMinutes()}:${new Date(values.time_from).getSeconds()}`,
        time_to: `${new Date(values.time_to).getHours()}:${new Date(
          values.time_to
        ).getMinutes()}:${new Date(values.time_to).getSeconds()}`,
      };

      const createConvertedValues = {
        name: values.name,
      };
      if (!isEdit) {
        try {
          const response = await axiosPrivate.post(
            "/shift",
            createConvertedValues
          );

          if (response?.data.code === 200) {
            resetForm();
            addStateShift(response.data.data);
            setSubmitting(false);
            enqueueSnackbar(
              "Shift created successfully",

              {
                variant: "success",
                action: (key) => (
                  <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </MIconButton>
                ),
              }
            );

            navigate(`/shifts/${response.data.data.id}/edit`);
          }
        } catch (error) {
          console.error(error);
          setSubmitting(false);
          setErrors(error);
        }
      } else {
        try {
          const response = await axiosPrivate.put(
            `/shift/${currentShift.id}`,
            convertedValues
          );

          if (response?.data.code === 200) {
            resetForm();
            setSubmitting(false);
            enqueueSnackbar("Shift updated successfully", {
              variant: "success",
              action: (key) => (
                <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              ),
            });

            navigate(`/shifts`);
          }
        } catch (error) {
          console.error(error);
          setSubmitting(false);
          setErrors(error);
        }
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3, mb: 10 }} className={"smallForms"}>
                <Stack spacing={3} sx={{ mb: 3 }}>
                  <TextField
                    fullWidth
                    label='Name'
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  {isEdit && (
                    <TimePicker
                      inputFormat='HH:mm:ss'
                      label='Start time'
                      value={values.time_from}
                      onChange={(time) =>
                        setFieldValue("time_from", time.toString())
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  )}
                </Stack>

                {isEdit && (
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                    {isEdit && (
                      <TimePicker
                        inputFormat='HH:mm:ss'
                        label='End time'
                        value={values.time_to}
                        onChange={(time) =>
                          setFieldValue("time_to", time.toString())
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    )}
                  </Stack>
                )}
                <Stack spacing={3}>
                  <Box
                    sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                  >
                    <LoadingButton
                      type='submit'
                      variant='contained'
                      loading={isSubmitting}
                    >
                      {!isEdit ? "Create Shift" : "Save Changes"}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </LocalizationProvider>
  );
}
