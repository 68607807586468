import { useAuthContext } from "../context/auth/authContext";
import { getIndividualRoles } from "../utlis/convertRoles";

const usePermissions = (type) => {
  const { roles } = useAuthContext();
  const rolesArrays = getIndividualRoles(roles);


  let permissions = {};

  if(type === "franchise") {
    if (rolesArrays === "All") {
      permissions = {
        Bar: true,
        Beach: true,
        Restaurant: true,

      }
    } else {
      permissions = {
        Bar: rolesArrays[type]?.includes("Bar") || false,
        Beach: rolesArrays[type]?.includes("Beach") || false,
        Restaurant: rolesArrays[type]?.includes("Restaurant") || false,
      }
    }

  } else {
    if (rolesArrays === "All") {
      permissions = {
        all: true,
        preview: true,
        edit: true,
        delete: true,
        create: true,
        list: true,
      };
    } else {
      permissions = {
        all: rolesArrays[type]?.includes("All") || false,
        preview: rolesArrays[type]?.includes("Preview") || false,
        edit: rolesArrays[type]?.includes("Edit") || false,
        delete: rolesArrays[type]?.includes("Delete") || false,
        create: rolesArrays[type]?.includes("Create") || false,
        edit_only_own : rolesArrays[type]?.includes("Edit_only_own") || false,
        list: rolesArrays[type]?.includes("List") || false,
        approved_orders: rolesArrays[type]?.includes("List") || false,
      };
    }



  }
  return permissions;

};

export default usePermissions;
