import React, { useReducer, useContext, useEffect } from "react";
import axios from "../../api/axios";
import convertRoles from "../../utlis/convertRoles";

import {
  ADD_USER,
  CLEAR_ALERT,
  DELETE_USER,
  DISPLAY_ALERT,
  GET_GROUPS,
  GET_USERS, HANDLE_ACTIVE_RESERVATION_LIST_CHANGE, HANDLE_ACTIVE_RESERVATION_LIST_DELETE,
  LOGIN_USER_BEGIN,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGENERATE_ACCESS_TOKEN,
  UPDATE_USER_FRANCHISE,
  SET_ROWS_PER_PAGE
} from "../actions";

import AuthReducer from "./authReducer";
import useLocalStorage from "../../hooks/useLocalStorage";
const token = localStorage.getItem("cpd_token");
const refresh = localStorage.getItem("cpd_refresh");
const user = localStorage.getItem("cpd_user");
const roles = localStorage.getItem("cpd_roles");
const activeReservations = localStorage.getItem("cpd_active_res");
const rowsPerPage = localStorage.getItem("cpd_rows");

const initialState = {
  showAlert: false,
  alertText: "",
  alertType: "",
  user: user ? JSON.parse(user) : null,
  roles: roles ? JSON.parse(roles) : null,
  accessToken: token ? JSON.parse(token) : null,
  refreshToken: refresh ? JSON.parse(refresh) : null,
  expire: "",
  users: [],
  groups: [],
  activeReservations: activeReservations ? JSON.parse(activeReservations) : [],
  rowsPerPage:rowsPerPage ? JSON.parse(rowsPerPage) : 5,
   example:false
};

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);


  const LOGIN_URL = "/authenticate";

  const addUserToLocalStorage = ({ user }, { roles }, token, refresh) => {
    localStorage.setItem("cpd_user", JSON.stringify(user));
    localStorage.setItem("cpd_roles", JSON.stringify(roles));
    localStorage.setItem("cpd_token", JSON.stringify(token));
    localStorage.setItem("cpd_refresh", JSON.stringify(refresh));
  };

  const updateTokensStorage = (token, refresh) => {
    localStorage.setItem("cpd_token", JSON.stringify(token));
    localStorage.setItem("cpd_refresh", JSON.stringify(refresh));
  };

  const removeUserFromLocalStorage = () => {
    localStorage.removeItem("cpd_user");
    localStorage.removeItem("cpd_token");
    localStorage.removeItem("cpd_refresh");
    localStorage.removeItem("cpd_roles");
    localStorage.removeItem("cpd_settings");
    localStorage.removeItem("cpd_active_res");
  };

  const displayAlert = (alertType, alertText) => {
    dispatch({ type: DISPLAY_ALERT, payload: { alertType, alertText } });
  };


  const setRowsPerPage = (value) => {
    dispatch({ type: SET_ROWS_PER_PAGE, payload: { value } });

    localStorage.setItem("cpd_rows", JSON.stringify(value));
  };

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 3000);
  };

  const loginUser = async ({ username, password, deviceid, alertText }) => {
    try {
      const { data } = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username, password, deviceid})
      );

      if (data?.code === 200) {
        const { user, session, permissions } = data?.data;

        console.log("USER PERMISSIONS", permissions)
        let roles = permissions?.Partial || "All";
        let convertedRoles;
        roles !== "All"
          ? (convertedRoles = convertRoles(roles))
          : (convertedRoles = "All");

        const { access_token, refresh_token, expire } = session;

        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: {
            user,
            access_token,
            roles: convertedRoles,
            refresh_token,
            expire,
            alertText,
            permissions,
          },
        });

        addUserToLocalStorage(
          { user },
          { roles: convertedRoles },
          access_token,
          refresh_token
        );
      } else {
        displayAlert("error", data.message);
      }
    } catch (error) {
      dispatch({
        type: LOGIN_USER_ERROR,
      });
      console.log(error);
    }
    clearAlert();
  };

  const regenerateTokens = (newAccessToken, newRefreshToken) => {
    dispatch({
      type: REGENERATE_ACCESS_TOKEN,
      payload: { newAccessToken, newRefreshToken },
    });
    updateTokensStorage(newAccessToken, newRefreshToken);
  };

  const logoutUser = async () => {
    dispatch({ type: LOGOUT_USER });
    removeUserFromLocalStorage();
  };

  const setStateUsers = (users) => {
    dispatch({
      type: GET_USERS,
      payload: { users },
    });
  };

  const setStateGroups = (groups) => {
    dispatch({
      type: GET_GROUPS,
      payload: { groups },
    });
  };

  const deleteStateUser = (userId) => {
    dispatch({
      type: DELETE_USER,
      payload: { userId },
    });
  };

  const addStateUser = (user) => {
    dispatch({
      type: ADD_USER,
      payload: { user },
    });
  };

  const updateUserFranchise = (user) => {
    dispatch({
      type: UPDATE_USER_FRANCHISE,
      payload: { user },
    });
    localStorage.setItem("cpd_user", JSON.stringify(user));
    localStorage.removeItem("cpd_active_res");
    localStorage.removeItem('cpd_badgeNum');
  };

  const handleActiveReservationListChange = (reservationId) => {
    dispatch({
      type: HANDLE_ACTIVE_RESERVATION_LIST_CHANGE,
      payload: { reservationId },
    });
    const index = state.activeReservations.indexOf(reservationId)

    if(index !== -1) {
      localStorage.setItem(
          "cpd_active_res",
          JSON.stringify([...state.activeReservations])
      );
    } else {
      localStorage.setItem(
          "cpd_active_res",
          JSON.stringify([...state.activeReservations, reservationId])
      );
    }
  };


  const handleActiveReservationListDelete = (reservationId) => {
    dispatch({
      type: HANDLE_ACTIVE_RESERVATION_LIST_DELETE,
      payload: { reservationId },
    });

    localStorage.setItem(
        "cpd_active_res",
        JSON.stringify([
          ...state.activeReservations.filter((res) => res !== reservationId),
        ])
    );
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginUser,
        regenerateTokens,
        logoutUser,
        setStateUsers,
        deleteStateUser,
        setStateGroups,
        addStateUser,
        updateUserFranchise,
        handleActiveReservationListDelete,
        handleActiveReservationListChange,
        setRowsPerPage
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthProvider, initialState, useAuthContext };
