
import CountryCodeDialog from "./CountryCodeDialog";

const FormRow = ({ type, name, value, handleFormChange, labelText, handleCountryCodeSelect, countryCode}) => {
  return (
    <div className='form-row'>
      <label htmlFor={name} className='form-label'>
        {labelText || name}
      </label>
        {name === "phone" ?
            <div className="countryCodeRow">
                <CountryCodeDialog handleCountryCodeSelect={handleCountryCodeSelect} countryCode={countryCode}/>
                <input
                    type={type}
                    value={value}
                    name={name}
                    onChange={handleFormChange}
                    className='form-input'
                />
            </div>
           :
            <input
                type={type}
                value={value}
                name={name}
                onChange={handleFormChange}
                className='form-input'
            />
        }

    </div>
  );
};

export default FormRow;
