function splitByDot(str) {
     let beforeDot= null
     let afterDot= ""
    if(str) {

        const dotIndex = str.indexOf('.');
        beforeDot = str.slice(0, dotIndex);
        afterDot = str.slice(dotIndex + 1);
    }

    return {
        beforeDot: beforeDot,
        afterDot: afterDot
    };
}

export default splitByDot;
