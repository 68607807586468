import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
// material
import {
  IconButton,
} from "@mui/material";
import AlertDialog from "./AlertDialog";
// routes

// ----------------------------------------------------------------------

ListCtaMenu.propTypes = {
  onDelete: PropTypes.func,
  id: PropTypes.string,
  permissions: PropTypes.object,
};

const ListCtaWrapper = styled(
  "div",
  {}
)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
});

export default function ListCtaMenu({ onDelete, id, permissions }) {
  return (
    <ListCtaWrapper>
      {(permissions?.all || permissions?.edit) && (
        <IconButton component={RouterLink} to={`${id}/edit`}>
          <EditIcon />
        </IconButton>
      )}

      {permissions?.all || permissions?.delete & (onDelete !== undefined) ? (
        <AlertDialog onAction={onDelete} />
      ) : null}
    </ListCtaWrapper>
  );
}
