import { Grid, Skeleton } from "@mui/material";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import HeaderBreadcrumbs from "../../components/layoutHelpers/HeaderBreadcrumbs";

import GroupNewForm from "../../components/dashboard/user/GroupNewForm";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useAuthContext } from "../../context/auth/authContext";

const AddGroup = () => {
  const { groups, setStateGroups } = useAuthContext();
  const { pathname } = useLocation();
  const { id } = useParams();
  const isEdit = pathname.includes("edit");
  const axiosPrivate = useAxiosPrivate();
  const currentGroup = groups.find((group) => group.id === id);
  const GROUP_URL = `/group/${id}`;

  const GROUPS_URL = "/groups";

  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getGroupData = () => {
      try {
        axiosPrivate(GROUPS_URL, {
          signal: controller.signal,
        })
          .then((response) => {
            if (response.data.code === 200 && isMounted) {
              isMounted && setStateGroups(response.data.data);
            }
            return axiosPrivate(GROUP_URL, {
              headers: {
                Groupid: `${id}`,
              },
              signal: controller.signal,
            });
          })
          .then((response) => {
            if (response.data.code === 200 && isMounted) {
              isMounted && setPermissions(response.data.data);
            }
          });
      } catch (error) {
        console.log(error);
      }
    };

    getGroupData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <GridPageWrapper>
      <Grid item xs={12}>
        <HeaderBreadcrumbs
          heading={isEdit ? "Edit Group" : "Add Group"}
          links={[
            { name: "Dashboard", href: "/" },
            { name: "Group List", href: "/groups" },
            { name: !isEdit ? "New Group" : "Edit Group" },
          ]}
        />
        {permissions.permissions ? (
          <GroupNewForm
            isEdit={isEdit}
            currentGroup={currentGroup}
            permissions={permissions}
          />
        ) : (
          <Skeleton width='100%' height='700px' sx={{ marginTop: "-120px" }} />
        )}
      </Grid>
    </GridPageWrapper>
  );
};
export default AddGroup;
