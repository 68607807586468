import styled, { css } from "styled-components";

const ReservationBodyWrapper = styled.div`
  padding: 0 5px 125px 5px;
  position: relative;
  overflow-x: scroll;
  @media (min-width: 992px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .floatingButtonWrapper {
    position: fixed;
    bottom: 75px;
    right: 75px;
    @media (max-width: 1200px) {
      right: 25px;
    }
  }
  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    ul {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      flex-wrap: wrap;
      @media (max-width: 768px) {
        margin: 0 0 25px 0;
      }
      li {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;

        @media (max-width: 992px) {
          font-size: 18px;
        }

        @media (max-width: 575px) {
          font-size: 16px;
        }

        @media (max-width: 475px) {
          font-size: 14px;
        }
      }

      li:not(:first-of-type) {
        padding: 0 0 0 20px;
        position: relative;
        @media (max-width: 475px) {
          padding: 0 0 0 10px;
        }
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 10px;
          top: 0;
          height: 100%;
          width: 2px;
          background: #000;
          @media (max-width: 475px) {
            left: 5px;
          }
        }
      }
    }
  }
  .description-box {
    padding: 25px 0 0 0;
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
`;

export const ListViewWrapper = styled.div`
  position: relative;
  height: auto;
  min-height: 500px;
  width: 100%;
  min-width: 666px;

  
  .select-all-btn {
    position: absolute;
    left: 0;
    top: 20px;
    width: 150px;
    z-index: 11;
    @media (max-width: 1200px) {
      width: 75px;
      font-size: 10px;
    }
  }
  .timeline-grid {
    display: flex;
    padding-left: 150px;
    left: 0;
    width: 100%;
    top:25px;
    position: absolute;

    @media (max-width: 1200px) {
      padding-left: 75px;
    }
    div {
      text-align: center;
      border-right: 1px solid rgba(128, 128, 128, 0.1);
      flex: 1;
    }

    span {
      font-size: 10px;
      font-weight: bold;
      display: block;
      @media (max-width: 666px) {
        font-size: 8px;
      }
    }
  }
  .subservice-wrapper {
    z-index: 1;
    padding-top: 75px;

    .single-sub-service {
      display: flex;
      padding-bottom: 15px;
      .left {
        width: 150px;
        padding: 10px 10px 0 10px;
        border: 1px solid grey;
        border-radius: 4px;
        @media (max-width: 1200px) {
          width: 75px;
          padding: 5px 5px 0 5px;
        }
        &.active {
          background: rgba(255, 223, 0, 0.5);
        }

        .bottom-action-bar {
          button {
            display: block;
            width: 100%;
            line-height: initial;
          }
        }
        
        .subservice-title {
          @media (max-width: 1200px) {
            font-size: 12px;
          }
        }
      }

      .right {
        flex: 1;
        position: relative;
        border-bottom: 1px solid rgba(128, 128, 128, 0.5);
         overflow: hidden;
        .time-range-div {
          position: absolute;
          height: 100%;
          top: 0;
          border-radius: 4px;
          border: 1px solid grey;
          transition: all 0.3s;
          background: rgba(220, 20, 60, 0.8);
          display: flex;
           cursor: pointer;
          flex-wrap: wrap;
            &:hover {
              background: rgba(220, 20, 60, 1);
            }
          .inner {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 10px;
            font-weight: bold;
            height: 100%;
            flex: 1;
            position: relative;

            p {
              color: #fff;
              margin: 5px 0;
              font-weight: lighter;
              
              span {
                font-weight: bold;
                padding:0 2.5px
              }
            }
          }

          .delete-box {
            display: none;
            position: relative;
            height: 100%;
            min-width: 25px;
            .MuiButtonBase-root {
              position: absolute;
              right: 0;
              top: 0;
              display: none;

              &:hover {
                svg {
                  filter: brightness(0) invert(23%) sepia(98%) saturate(6561%)
                    hue-rotate(341deg) brightness(89%) contrast(94%);
                }
              }
            }
          }
          
          .switch-box {
            display: none;
            position: relative;
            height: 100%;
            min-width: 25px;
          }
          .author-names {
            position: absolute;
            font-size: 10px;
            font-weight: bold;
            color:white;
            top:5px;
            left: 5px;
          }
          
          &.editable {
        
            background: rgba(255, 223, 0, 0.8);
            .inner {
              p {
                color: #000;
                margin: 5px 0;
              }
            }
            .delete-box {
              display: flex;
              align-items: center;
              justify-content: center;
            
              .MuiButtonBase-root {
                display: block;
                position: relative;
              }
            }

            .switch-box {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .author-names {
              color:black;
           
            }
            

          }
        }
      }
      .action-bar {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        justify-content: space-between;

        li {
          &:hover {
            cursor: pointer;
          }
        }
      }

      .bottom-action-bar {
        text-align: center;
      }
    }
  }
`;
export const CalendarFrame = styled.div`
  width: 100%;

  border-radius: 4px;
`;

export const CalendarHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  display: flex;
  justify-content: space-between;
  background-color: #f5f6fa;
  border-radius: 4px;
`;

export const CalendarButton = styled.div`
  cursor: pointer;
`;

export const CalendarBody = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
export const CalendarTitle = styled.div`
  width: calc(100% / 7);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
export const CalendarDay = styled.div`
  width: 13.5%;
  margin: 0.39%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid lightgrey;

  @media (max-width: 768px) {
    height: 75px;
  }

  ${(props) =>
    props.isToday &&
    css`
      border: 1px solid lightgrey;
    `}

  ${(props) =>
    props.isSelected &&
    css`
      background-color: #eee;
    `}
`;

export default ReservationBodyWrapper;
