import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../api/axios";
import { useAuthContext } from "../context/auth/authContext";
import useRefreshToken from "./useRefreshToken";
const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { user, accessToken, logoutUser } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
          config.headers["Userid"] = `${user?.id}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );
    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newTokens = await refresh();
          prevRequest.headers[
            "Authorization"
          ] = `Bearer ${newTokens.accessToken}`;
          prevRequest.headers["Userid"] = `${user?.id}`;
          prevRequest.body = JSON.stringify({
            refresh_token: newTokens.refreshToken,
          });
          return axiosPrivate(prevRequest);
        }

        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          logoutUser();
          enqueueSnackbar(
            "Unauthorized! Your session has expired. Please log back in.",
            {
              variant: "error",
            }
          );
          navigate("/");
        }
        if (error?.response?.status === 405 && !prevRequest?.sent) {
          prevRequest.sent = true;
          navigate("/405");
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [user, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
