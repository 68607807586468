// import { sentenceCase } from "change-case";
import { useState } from "react";

// material
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel, Button,

} from "@mui/material";

import Scrollbar from "../../helpers/Scrollbar";
import Label from "../../helpers/Label";
import ReservationsCtaMenu from "../../helpers/ReservationsCtaMenu";
import  SingleReservationModal from "./SingleReservationModal"
import ReservationListPopover from "./ReservationListPopover";
import moment from "moment";
import {useAuthContext} from "../../../context/auth/authContext";
import StatusChipsComponent from "./StatusChipsComponent";
import {createInitials} from "../../../utlis/createInitials";
import {useBookingContext} from "../../../context/booking/bookingContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import usePermissions from "../../../hooks/usePermssions";



const TABLE_HEAD = [
  { id: "id", label: "ID", alignRight: false },
  { id: "id2", label: "Client Name", alignRight: false },
  { id: "id3", label: "Dates", alignRight: false },
  { id: "id4", label: "Status", alignRight: false },
  { id: "id5", label: "C.E.", alignRight: false },
  { id: "id6", label: "Subservices", alignRight: false },
  { id: "id7", label: "Note", alignRight: false },
  { id: "id8", label: "Approved", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function SingleReservationList({ ...props }) {
  const theme = useTheme();
  const {updateStateOrders} = useBookingContext();
  const [textExpanded,setTextExpanded] = useState(false)
  const {setRowsPerPage, rowsPerPage} = useAuthContext();
  const axiosPrivate = useAxiosPrivate();
  const { orders, page, setPage,totalOrdersNum, setArrivedState } = props;
   const {user} = useAuthContext();

  const permissionsOrder = usePermissions("order");

  console.log("permissionsOrder",permissionsOrder)

   const canApprove = user.group === "1";

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteUser = (userId) => {
    // dispatch(deleteUser(userId));
  };


  const handleApproveOrderClick = async (id) =>  {
    try {
      const response = await axiosPrivate.patch(
          `/order/approve/${id}` );

      if (response?.data.code === 200) {
        updateStateOrders(response.data.data)
        //DODAJES DA RADI FETCH NA ODRER TABLE I TO JE TO
        setArrivedState(prev => !prev)
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Card className='reservationTableWrapper'>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 750 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell key={headCell.id}>
                    <TableSortLabel hideSortIcon>
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((row) => {

                const {
                  id,
                  name,
                  arrived_at,
                  item_arrived,
                  item_arrived_at,
                  status,
                  current_editor,
                  editor_name,
                  note,
                  subservices,
                    dates,
                    approved,
                    created_by
                } = row;


                const initials = editor_name ? createInitials(editor_name) : "";
                let statusColor;
                let statusTarget;
                let statusValue;

                switch (status) {
                  case "1":
                    statusColor = "info";
                    statusTarget = "P";
                    statusValue = "In Progress";
                    break;
                  case "2":
                    statusColor = "success";
                    statusTarget = "F";
                    statusValue = "Finished";
                    break;
                  case "4":
                    statusColor = "error";
                    statusTarget = "S";
                    statusValue = "Stopped";
                    break;
                  default:
                    statusColor = "success";
                    statusTarget = "P";
                    statusValue = "In Progress";
                    break;
                }
                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell>{id}</TableCell>
                    <TableCell align='left' className={arrived_at || item_arrived === "1" ? "activeArrival" : "pendingArrival"}>
                      <p>
                        {name || "Not entered!"}
                      </p>
                       <p>
                         {arrived_at || item_arrived === "1"? `Arrived At:` : "Not Arrived"}
                       </p>
                      {arrived_at && moment(arrived_at).format("h:mm DD.MM.YY")}
                      {!arrived_at && item_arrived === "1" && moment(item_arrived_at).format("h:mm DD.MM.YY")}
                    </TableCell>
                    <TableCell align='left'>
                      {dates}
                    </TableCell>

                    <TableCell align='left'>

                      <Label
                        variant={
                          theme.palette.mode === "light" ? "ghost" : "filled"
                        }
                        color={statusColor}
                      >
                        <ReservationListPopover target={statusTarget} value={statusValue}/>
                      </Label>

                    </TableCell>


                    <TableCell align='left'>    <ReservationListPopover target={initials} value={editor_name}/> </TableCell>

                    <TableCell align='left'>
                       <StatusChipsComponent subservices={subservices}/>
                    </TableCell>
                    <TableCell align='left'><p className={textExpanded ? "" : "text-clamped"} onClick={() => setTextExpanded(!textExpanded)}>{note}</p></TableCell>
                    <TableCell align='left'>
                      {approved === "1" ?
                          <Label
                              variant={
                                theme.palette.mode === "light" ? "ghost" : "filled"
                              }
                              color="success"
                          >
                            <ReservationListPopover target="Yes" value="Approved"/>
                          </Label>

                          :
                          canApprove ?
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={() => handleApproveOrderClick(id)}
                                >
                                  Approve
                                </Button>

                                :
                                <Label
                                    variant={
                                      theme.palette.mode === "light" ? "ghost" : "filled"
                                    }
                                    color="error"
                                >
                                  <ReservationListPopover target="No" value="Not Approved"/>
                                </Label>


                      }

                    </TableCell>
                    <TableCell align='right'>
                      {(permissionsOrder?.edit || (permissionsOrder?.edit_only_own && user?.username === created_by)) ?
                          <ReservationsCtaMenu
                              order_status={status}
                              current_editor={current_editor}
                              editor_name={editor_name}
                              id={id}
                          />
                          : null}
                      <SingleReservationModal order={row.id} setArrivedState={setArrivedState} canApprove={canApprove}/>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={totalOrdersNum}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/*<Pagination count={3} page={page} onChange={handleChangePage} />*/}


    </Card>
  );
}
