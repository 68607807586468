export const DISPLAY_ALERT = "DISPLAY_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";

export const REGENERATE_ACCESS_TOKEN = "REGENERATE_ACCESS_TOKEN";

export const GET_USERS = "GET_USERS";
export const DELETE_USER = "DELETE_USER";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER_FRANCHISE = "UPDATE_USER_FRANCHISE";

export const GET_GROUPS = "GET_GROUPS";
export const GET_ORDERS = "GET_ORDERS";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const GET_SERVICES = "GET_SERVICES";
export const GET_SUB_SERVICES = "GET_SUB_SERVICES";
export const GET_SHIFTS = "GET_SHIFTS";
export const ADD_SERVICE = "ADD_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";

export const ADD_SHIFT = "ADD_SHIFT";
export const DELETE_SHIFT = "DELETE_SHIFT";
export const SET_GLOBAL_SEARCH = "SET_GLOBAL_SEARCH";
export const ADD_ACTIVE_SUB_SERVICE = "ADD_ACTIVE_SUB_SERVICE";
export const DELETE_ACTIVE_SUB_SERVICE = "DELETE_ACTIVE_SUB_SERVICE";
export const SET_RESERVATION_COLOR = "SET_RESERVATION_COLOR";
export const SET_IS_LOADING_STATE = "SET_IS_LOADING_STATE";

export const SET_DYNAMIC_VAR = "SET_DYNAMIC_VAR";
export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_SOFT_DELETED_TIME_RANGES = "SET_SOFT_DELETED_TIME_RANGES";


export const SET_ORDER_DATE = "SET_ORDER_DATE";
export const SET_SELECTED_SERVICE = "SET_SELECTED_SERVICE";
export const SET_SELECTED_SHIFT = "SET_SELECTED_SHIFT";
export const SET_RESERVATION_STOP = "SET_RESERVATION_STOP";
export const SET_SELECTED_TIMEDURATIONS = "SET_SELECTED_TIMEDURATIONS";
export const SET_ALL_SELECTED_TIMEDURATIONS = "SET_ALL_SELECTED_TIMEDURATIONS";
export const SET_INITAL_TIMEDURATIONS = "SET_INITAL_TIMEDURATIONS";
export const DELETE_SELECTED_TIMEDURATIONS = "DELETE_SELECTED_TIMEDURATIONS";
export const SET_OPENED_POPOVER_RESRVATIONS = "SET_OPENED_POPOVER_RESRVATIONS";
export const HANDLE_SELECT_ALL_ACTIVE_RESERVATION_ITEMS_CHANGE = "HANDLE_SELECT_ALL_ACTIVE_RESERVATION_ITEMS_CHANGE";

export const SET_RESERVATION = "SET_RESERVATION";
export const SET_PREV_TIME_RANGE = "SET_PREV_TIME_RANGE";
export const HANDLE_ACTIVE_RESERVATION_ITEMS_DELETE = "HANDLE_ACTIVE_RESERVATION_ITEMS_DELETE";
export const HANDLE_ACTIVE_RESERVATION_LIST_DELETE =  "HANDLE_ACTIVE_RESERVATION_LIST_DELETE";
export const HANDLE_ACTIVE_RESERVATION_LIST_CHANGE = "HANDLE_ACTIVE_RESERVATION_LIST_CHANGE";
export const HANDLE_ACTIVE_RESERVATION_ITEMS_CHANGE = "HANDLE_ACTIVE_RESERVATION_ITEMS_CHANGE";
export const HANDLE_ACTIVE_RESERVATION_CHANGE = "HANDLE_ACTIVE_RESERVATION_CHANGE";
export const SET_SHOW_EXTRA_FILTERS = "SET_SHOW_EXTRA_FILTERS";
export const SET_SHOW_NOT_APPROVED = "SET_SHOW_NOT_APPROVED";
