import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { styled } from "@mui/system";

BookingAlertDialog.propTypes = {
    onDelete: PropTypes.func,
};

export default function BookingAlertDialog({ target, onAction, targetText}) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseAndDelete = () => {
        onAction();
        setOpen(false);
    };

    const AlertDialogWrapper = styled(
        "div",
        {}
    )({
        ".MuiMenuItem-root": {
            justifyContent: "flex-end",
            width: "auto",
        },
        ".MuiListItemIcon-root": {
            minWidth: "auto !important",
            marginRight: 0,
            svg: {
                width: 24,
                height: 24,
                color: "crimson",
            },
        },
    });

    return (
        <AlertDialogWrapper>

                <div onClick={handleClickOpen}>
                    {target}
                </div>


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>

                    {targetText}
                </DialogTitle>
                {/*<DialogContent>*/}
                {/*    <DialogContentText id='alert-dialog-description'>*/}
                {/*        This cannot be undone!*/}
                {/*    </DialogContentText>*/}
                {/*</DialogContent>*/}
                <DialogActions>
                    <Button onClick={handleClose} variant='contained'>
                        Go Back
                    </Button>
                    <Button
                        onClick={handleCloseAndDelete}
                        color='error'
                        variant='contained'
                    >
                        Confirm

                    </Button>
                </DialogActions>
            </Dialog>
        </AlertDialogWrapper>
    );
}
