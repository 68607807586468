// import { sentenceCase } from "change-case";
import { useState } from "react";

// material

import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";

import ListToolbar from "../../helpers/ListToolbar";
import ListHead from "../../helpers/ListHead";
import SearchNotFound from "../../helpers/SearchNotFound";
import ListCtaMenu from "../../helpers/ListCtaMenu";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { MIconButton } from "../../helpers/@material-extend";
import { useAuthContext } from "../../../context/auth/authContext";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "username", label: "Username", alignRight: false },
  { id: "group", label: "Group", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function SingleUserList({ users, userPermissions }) {
  const [filterName, setFilterName] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { deleteStateUser } = useAuthContext();

  const handleFilterByName = (event) => {};

  const handleDeleteUser = async (userId) => {
    try {
      const response = await axiosPrivate.delete(`/user/${userId}`, {
        headers: {
          id: `${userId}`,
        },
      });

      if (response?.data.code === 200) {
        enqueueSnackbar("User deleted successuflly", {
          variant: "success",
          action: (key) => (
            <MIconButton size='small' onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });

        deleteStateUser(userId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isUserNotFound = users.length === 0;

  return (
    <Card className='reservationTableWrapper'>
      <ListToolbar onFilterName={handleFilterByName} />
      <TableContainer>
        <Table>
          <ListHead headLabel={TABLE_HEAD} rowCount={users.length} />
          <TableBody>
            {users.map((row) => {
              const { id, first_name, last_name, username, group_name } = row;

              return (
                <TableRow hover key={id} tabIndex={-1} role='checkbox'>
                  <TableCell component='th' scope='row'>
                    <Stack direction='row' alignItems='center' spacing={2}>
                      <Avatar alt={first_name} />
                      <Typography variant='subtitle2' noWrap>
                        {first_name + " " + last_name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align='left'>{username}</TableCell>
                  <TableCell align='left'>{group_name}</TableCell>

                  <TableCell align='right'>
                    <ListCtaMenu
                      permissions={userPermissions}
                      id={id}
                      onDelete={() => handleDeleteUser(id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          {isUserNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Card>
  );
}
