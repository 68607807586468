import {
    Box, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";
import {v4 as uuid} from "uuid";
import React, {useState, useEffect} from "react";
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";



function SwitchTimeRange({subservices = [], handleSoftDeleteTimeRange, timeRange,selectedTimeDurations, setSelectedTimeDurations, handleActiveReservationItemsChange}) {
    const axiosPrivate = useAxiosPrivate();
    const [open, setOpen] = useState(false);
    const [filteredSubservices, setFilteredSubservices] = useState(subservices)
    const [selectedSubserviceId, setSelectedSubserviceId] = useState(filteredSubservices[0]?.id)
    const [selectedSubservice, setSelectedSubservice] = useState({})
    const [timeRangeToReplace, setTimeRangeToReplace] = useState({})
    const [reservedServices, setReservedServices] = useState([])


    useEffect(() => {
        setSelectedSubservice({...timeRange, subservice:selectedSubserviceId})

        return () => {
            setSelectedSubservice({})
        };
    }, [selectedSubserviceId]);




    const handleClose = (subserviceid, event, reason) => {
        if (reason !== "backdropClick") {
            setOpen(false);
        }

    };

    const handleChange = (e) => {
        const id = e.target.value;
        setSelectedSubserviceId(id);
        const timeRangeObject = selectedTimeDurations.find((obj) => obj.subservice === id);
        setTimeRangeToReplace(timeRangeObject);

        console.log("TIME RANGE OBJECT", timeRangeObject);
    };

    const handleSwitchConfirm = async () =>  {

     if(!timeRangeToReplace?.id) {
         handleSoftDeleteTimeRange();
         setOpen(false);
         setSelectedTimeDurations(selectedSubservice)
         const value = {
             id: selectedSubservice.id,
             subservice: selectedSubservice.subservice,
             date: selectedSubservice.date,
             time_from: selectedSubservice.time_from,
             time_to: selectedSubservice.time_to,
             state_item:true,
         };

         handleActiveReservationItemsChange({ value });
     } else {
         try {
             const response = await axiosPrivate(`/order/item/replace/${timeRangeToReplace.id}/${timeRange.id}`);

             if (response.data.code === 200 ) {
                 window.location.reload();
             }

         } catch (error) {
             console.log(error);
         }
     }


    }

    const handleOpen = () => {

        //OVO JE BILA PROVJERA KOJE TIME RANGOVE DA IZBACIS AKO SU ZAUZETI, ZA SAD OSTAVITI
        const servicesIDs = new Set(selectedTimeDurations.map(({ subservice }) => subservice ));
        let tempReservedServices = [
            ...subservices.filter(({ id }) => servicesIDs.has(id))
        ];
        tempReservedServices = tempReservedServices.map((obj) => obj.id)
         setReservedServices(tempReservedServices)
        // setFilteredSubservices(combinedServices)
        setFilteredSubservices(subservices)
        setSelectedSubserviceId(subservices[0]?.id)

        setOpen(true)
    }
    return (
        <>
            <IconButton onClick={() => handleOpen()} >
                <FindReplaceIcon  />
            </IconButton>
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={() => handleClose()}
        >
            <DialogTitle>Select New Subservice</DialogTitle>
            <DialogContent>
                <Box component='form' sx={{ display: "flex", flexWrap: "wrap" }}>
                    <FormControl sx={{ m: 1, minWidth: "100%" }}>
                        <InputLabel id='select-subservice'>Subservice</InputLabel>
                        <Select
                            labelId='select-subservice'
                            id='select-subservice'
                            value={selectedSubserviceId}
                            onChange={handleChange}
                            input={<OutlinedInput label='Time From' />}
                        >
                            {filteredSubservices?.map((subservice) => {
                                const isSelected = reservedServices.includes(subservice.id);
                                return (
                                    <MenuItem key={uuid()} value={subservice.id} sx={{
                                        backgroundColor: isSelected ? 'error.main' : 'inherit',
                                        color: isSelected ? 'common.white' : 'inherit',
                                        '&:hover': {
                                            backgroundColor: isSelected
                                                ? 'error.main'
                                                : 'action.hover',
                                            color: 'common.white',
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: isSelected
                                                ? 'error.main'
                                                : 'primary.light',
                                            color: 'common.white',
                                        },
                                    }}>
                                        {subservice.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color="error" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    variant='outlined'
                    color="success"
                    onClick={handleSwitchConfirm}

                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
}

export default SwitchTimeRange;
