import { Button, Grid, Skeleton } from "@mui/material";
import GridPageWrapper from "../../assets/wrappers/GridPageWrapper";
import SingleSubServicesList from "../../components/dashboard/services/SingleSubServicesList";
import HeaderBreadcrumbs from "../../components/layoutHelpers/HeaderBreadcrumbs";
import { Link as RouterLink, useParams } from "react-router-dom";
import { MdOutlineAppRegistration } from "react-icons/md";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import usePermissions from "../../hooks/usePermssions";


const SubServicesList = () => {
  const axiosPrivate = useAxiosPrivate();
  const [subServices, setSubServices] = useState(null);
  const [currentService, setCurrentService] = useState();
  const { service } = useParams();
  const permissons = usePermissions("service");
  const SUB_SERVICES_URL = `/subservices/${service}`;
  const SERVICE_URL = `/service/${service}`;

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getSubServices = async () => {
      try {
        axiosPrivate(SUB_SERVICES_URL, {
          signal: controller.signal,
        })
          .then((response) => {
            if (response.data.code === 200 && isMounted) {
              setSubServices(response.data.data || []);
            } else {
              isMounted && setSubServices([]);
            }

            return axiosPrivate(SERVICE_URL, {
              signal: controller.signal,
            });
          })
          .then((response) => {
            if (response.data.code === 200 && isMounted) {
              setCurrentService(response.data.data);
            }
          });
      } catch (error) {
        console.log(error);
      }
    };

    getSubServices();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <GridPageWrapper>
      <Grid item xs={12}>
        {permissons.all || permissons.create ? (
          <HeaderBreadcrumbs
            heading={`Subservices for ${currentService?.service.name || ""}`}
            links={[
              { name: "Dashboard", href: "/" },
              {
                name: `${currentService?.service.name || ""}`,
                href: `/services/${currentService?.service.id}/edit`,
              },
              { name: "Subservices List" },
            ]}
            action={
              <Button
                variant='contained'
                component={RouterLink}
                to={`add-subservice`}
                startIcon={<MdOutlineAppRegistration />}
              >
                New Subservice
              </Button>
            }
          />
        ) : (
          <HeaderBreadcrumbs
            heading='Services List'
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Services List" },
            ]}
          />
        )}

        {subServices ? (
          <SingleSubServicesList
            subServices={subServices}
            currentService={currentService}
            subSpermissons={permissons}
            setSubServices={setSubServices}
          />
        ) : (
          <Skeleton width='100%' height='700px' sx={{ marginTop: "-120px" }} />
        )}
      </Grid>
    </GridPageWrapper>
  );
};
export default SubServicesList;
