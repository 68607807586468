import {
    SET_SHOW_EXTRA_FILTERS,
    SET_SHOW_NOT_APPROVED
} from "../actions";

const utilityReducer = (state, { type, payload }) => {
    switch (type) {
        case SET_SHOW_EXTRA_FILTERS:
            return {
                ...state,
                showExtraFilters: payload.value,
            };

            case SET_SHOW_NOT_APPROVED:
            return {
                ...state,
                showNotApproved: payload.value,
            };
        default:
            return state;
    }
};

export default utilityReducer;
