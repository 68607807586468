import PropTypes from "prop-types";
import {  ListItemIcon, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

ReserationsAlertDialog.propTypes = {
  onEdit: PropTypes.func,
};

export default function ReserationsAlertDialog({ id }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAndTakeOver = () => {
    navigate(`/create-reservation/edit/${id}`);
    setOpen(false);
  };

  const AlertDialogWrapper = styled(
    "div",
    {}
  )({
    ".MuiMenuItem-root": {
      justifyContent: "flex-end",
      width: "auto",
      padding: "8px",
    },
    ".MuiListItemIcon-root": {
      minWidth: "auto !important",
      marginRight: 0,
      svg: {
        width: 24,
        height: 24,
        color: "crimson",
      },
    },
  });

  return (
    <AlertDialogWrapper>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Are you sure you want take over editing this reservation ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Current editor will be unable to finish the reservation!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='contained'>
            Go Back
          </Button>
          <Button
            onClick={handleCloseAndTakeOver}
            color='error'
            variant='contained'
          >
            Take Over
          </Button>
        </DialogActions>
      </Dialog>
    </AlertDialogWrapper>
  );
}
