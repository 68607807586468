import * as Yup from "yup";
import PropTypes from "prop-types";
import {  useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { RiLockPasswordFill } from "react-icons/ri";

// material
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
  IconButton,
  Button,
  InputAdornment,
} from "@mui/material";
import Label from "../../helpers/Label";
import { MIconButton } from "../../helpers/@material-extend";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useAuthContext } from "../../../context/auth/authContext";
// utils

// routes

// ----------------------------------------------------------------------

UserNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function UserNewForm({ isEdit, currentUser, groups }) {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(isEdit ? false : true);
  const axiosPrivate = useAxiosPrivate();

  const { addStateUser } = useAuthContext();


  const NewUserSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    username: Yup.string().required("Username is required").email(),
    password: changePassword && Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),

    password2:changePassword && Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id:currentUser?.id || null,
      first_name: currentUser?.first_name || "",
      last_name: currentUser?.last_name || "",
      username: currentUser?.username || "",
      password: "",
      password2: "",
      banned: currentUser?.banned * 1 || 0,
      group: currentUser?.group || "",
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {

      if (!isEdit) {
        const userForCreate = {
          first_name: values.first_name,
          last_name: values.last_name,
          username: values.username,
          password: values.password,
          password2: values.password2,
        };
        try {
          const response = await axiosPrivate.post("/user", userForCreate);

          if (response?.data.code === 200) {
            setSubmitting(false);
            addStateUser(response.data.data)
            setChangePassword(false)
            enqueueSnackbar(
                !isEdit ? "User created successfully" : "User updated successfully",
                {
                  variant: "success",
                  action: (key) => (
                      <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill}/>
                      </MIconButton>
                  ),
                }
            );

            navigate(`/users/${response.data.data.id}/edit`);
          }
        } catch (error) {
            console.error(error);
            setSubmitting(false);
            setErrors(error);
        }
      } else {
        let newUserObject;
        if(changePassword) {
          newUserObject = values
        } else {
          newUserObject ={
            id:values.id,
            username:values.username,
            first_name: values.first_name,
            last_name: values.last_name,
            banned:values.banned,
            group:values.group
          }
        }

        try {
          const response = await axiosPrivate.put(`/user/${values.id}`, newUserObject);
          if (response?.data.code === 200) {
            resetForm();
            setSubmitting(false);
            enqueueSnackbar(
                "User updated successfully",
                {
                  variant: "success",
                  action: (key) => (
                      <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill}/>
                      </MIconButton>
                  ),
                }
            );

            // navigate(`/users`);
          }
        } catch (error) {
          console.error(error);
          setSubmitting(false);
          setErrors(error);
        }
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ marginBottom: 10 }}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }} className={"smallForms"}>
              {isEdit && (
                <Label
                  color={values.banned === 0 ? "success" : "error"}
                  sx={{
                    textTransform: "uppercase",
                    marginBottom: "25px",
                  }}
                >
                  {values.banned=== 0 ? "Active" : "Banned"}
                </Label>
              )}

              <Stack spacing={3}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    fullWidth
                    label='First Name'
                    {...getFieldProps("first_name")}
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />

                  <TextField
                    fullWidth
                    label='Last Name'
                    {...getFieldProps("last_name")}
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Stack>

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    fullWidth
                    label='Email/Username'
                    {...getFieldProps("username")}
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                  />

                  {isEdit && !changePassword ? (
                    <Button
                      variant='contained'
                      startIcon={<RiLockPasswordFill />}
                      sx={{ minWidth: "250px" }}
                      onClick={() => setChangePassword(true)}
                    >
                      Change Password
                    </Button>
                  ) : (
                    <>
                      <TextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        label='Password'
                        {...getFieldProps("password")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                edge='end'
                                onClick={() => setShowPassword((prev) => !prev)}
                              >
                                <Icon
                                  icon={showPassword ? eyeFill : eyeOffFill}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />

                      <TextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        label='Confirm Password'
                        {...getFieldProps("password2")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                edge='end'
                                onClick={() => setShowPassword((prev) => !prev)}
                              >
                                <Icon
                                  icon={showPassword ? eyeFill : eyeOffFill}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.password2 && errors.password2)}
                        helperText={touched.password2 && errors.password2}
                      />
                      {
                        isEdit &&
                        <Button
                            variant='contained'
                            startIcon={<RiLockPasswordFill />}
                            sx={{ minWidth: "75px" }}
                            onClick={() => setChangePassword(false)}
                        >
                          Back
                        </Button>

                      }

                    </>
                  )}
                </Stack>

                {isEdit && (
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 3, sm: 2 }}
                  >
                    <TextField
                      select
                      fullWidth
                      label='Group'
                      placeholder='Group'
                      {...getFieldProps("group")}
                      SelectProps={{ native: true }}
                      error={Boolean(touched.group && errors.group)}
                      helperText={touched.group && errors.group}
                    >
                      <option disabled value=""></option>
                      {groups.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>

                    <Card sx={{ p: 1, minWidth: "250px" }}>
                      <FormControlLabel
                        labelPlacement='start'
                        control={
                          <Switch
                            onChange={(event) =>
                              setFieldValue(
                                "banned",
                                event.target.checked ? 1 : 0
                              )
                            }
                            checked={values.banned === 1}
                          />
                        }
                        label={
                          <>
                            <Typography variant='subtitle2'>Banned</Typography>
                          </>
                        }
                        sx={{
                          mx: 0,

                          width: 1,
                          justifyContent: "space-between",
                        }}
                      />
                    </Card>
                  </Stack>
                )}

                <Box
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                  >
                    {!isEdit ? "Create User" : "Save Changes"}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
