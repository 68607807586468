import * as Yup from "yup";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik, getIn } from "formik";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";

// material
import {} from "@mui/material";
// material
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Label from "../../helpers/Label";
import { MIconButton } from "../../helpers/@material-extend";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

// utils

// routes

// ----------------------------------------------------------------------

UserNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentGroup: PropTypes.object,
};

export default function UserNewForm({ isEdit, currentGroup, permissions }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const axiosPrivate = useAxiosPrivate();

  const initalPermissions = permissions.checked;
  const permissionsResponse = permissions?.permissions?.Partial;

  console.log("permissions",permissions)

  const modulesArr = Object.keys(permissionsResponse);
  const methodsArr = Object.entries(permissionsResponse);

  const isPartial = initalPermissions.Partial !== undefined;

  let initialModulesArr = [];

  if (isPartial) {
    initialModulesArr = Object.keys(initalPermissions.Partial);
  }

  const NewGroupSchema = Yup.object().shape({
    group: Yup.object().shape({
      name: Yup.string().required("Group Name is required"),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      group: { name: currentGroup?.name || "" },
      access: initalPermissions.All ? "All" : "Partial",
      modules: initialModulesArr || modulesArr,
      methods: initalPermissions.Partial || permissionsResponse,
    },

    validationSchema: NewGroupSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        const response = await axiosPrivate.put(
          `/group/${currentGroup.id}`,
          values,
          {
            headers: {
              Groupid: `${currentGroup.id}`,
            },
          }
        );

        if (response?.data.code === 200) {
          setSubmitting(false);
          enqueueSnackbar(
            !isEdit
              ? "Group created successfully"
              : "Group permissions updated successfully",
            {
              variant: "success",
              action: (key) => (
                <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              ),
            }
          );
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  let labelColor = "warning";
  let permssionText = "partial";
  if (!values.modules.length > 0) {
    labelColor = "error";
    permssionText = "none";
  }

  if (values.access === "All") {
    labelColor = "success";
    permssionText = "all";
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ marginBottom: 10 }}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }} className={"smallForms"}>
              {isEdit && (
                <Label
                  color={labelColor}
                  sx={{
                    textTransform: "uppercase",
                    marginBottom: "25px",
                  }}
                >
                  {`Permission: ${permssionText}`}
                </Label>
              )}

              <Stack spacing={2}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    label='Group Name'
                    {...getFieldProps("group.name")}
                    name='group.name'
                    error={Boolean(
                      getIn(touched, "group.name") &&
                        getIn(errors, "group.name")
                    )}
                    helperText={
                      getIn(touched, "group.name") &&
                      getIn(errors, "group.name")
                    }
                  />
                </Stack>

                {isEdit && (
                  <Stack direction='row' spacing={2}>
                    <Card sx={{ p: 1 }}>
                      <FormControlLabel
                        labelPlacement='start'
                        control={
                          <Switch
                            onChange={(event) =>
                              setFieldValue(
                                "access",
                                event.target.checked ? "All" : "Partial"
                              )
                            }
                            checked={values.access === "All"}
                          />
                        }
                        label={
                          <Typography variant='subtitle2'>
                            Full Access
                          </Typography>
                        }
                        sx={{
                          mx: 0,
                          width: 1,
                          justifyContent: "space-between",
                        }}
                      />
                    </Card>
                  </Stack>
                )}
                {values.access === "Partial" &&
                  methodsArr.map((module, i) => {
                    return (
                      <div key={i} sx={{ display: "flex" }}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.modules.includes(module[0])}
                              />
                            }
                            label={module[0]}
                            name={`modules`}
                            key={i}
                            value={module[0]}
                            onChange={formik.handleChange}
                          />
                        </div>
                        {module[1].map((submodule, i) => {
                          if (!values.modules.includes(module[0])) {
                            return;
                          }
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color='default'
                                  size='small'
                                  checked={values.methods[module[0]]?.includes(
                                    submodule
                                  )}
                                />
                              }
                              sx={{
                                paddingLeft: 4,
                              }}
                              label={submodule}
                              name={`methods[${module[0]}]`}
                              key={submodule}
                              value={submodule}
                              onChange={formik.handleChange}
                            />
                          );
                        })}
                      </div>
                    );
                  })}

                <Box
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                  >
                    {!isEdit ? "Create User" : "Save Changes"}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
