import * as React from 'react';
import Popover from '@mui/material/Popover';
import {IconButton} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {ReservationModalPopoverWrapper} from "../dashboard/booking/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function SingleReservationModalPopover({itemId}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [orderData, setOrderData] = React.useState(null);
    const axiosPrivate = useAxiosPrivate();
    const handleClick = async (event) => {
        setAnchorEl(event.currentTarget);

        try {
            const response = await axiosPrivate(
                `/order/log/${itemId}` );

            if (response?.data.code === 200) {

                setOrderData(response.data)
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton onClick={handleClick} sx={{marginLeft:"25px"}}>
                <VisibilityIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {orderData ?

                    <ReservationModalPopoverWrapper>
                        <dl>
                            <div>
                                <dt>ID:</dt>
                                <dd>{orderData?.data?.id || ""}</dd>
                            </div>

                            <div>
                                <dt>Date:</dt>
                                <dd>{orderData?.data?.datum || ""}</dd>
                            </div>

                            <div>
                                <dt>Action:</dt>
                                <dd>{orderData?.data?.action || ""}</dd>
                            </div>

                            <div>
                                <dt>Headers:</dt>
                                <dd>{orderData?.data?.headers || ""}</dd>
                            </div>

                            <div>
                                <dt>Method:</dt>
                                <dd>{orderData?.data?.method || ""}</dd>
                            </div>

                            <div>
                                <dt>URI:</dt>
                                <dd>{orderData?.data?.uri || ""}</dd>
                            </div>

                            <div>
                                <dt>Order ID:</dt>
                                <dd>{orderData?.data?.order || ""}</dd>
                            </div>

                            <div>
                                <dt>User ID:</dt>
                                <dd>{orderData?.data?.user || ""}</dd>
                            </div>
                        </dl>

                    </ReservationModalPopoverWrapper>
                :
                    <Box sx={{ display: 'flex', alignItems:"center", justifyContent:"center", minWidth:"330px", minHeight:"250px;" }}>
                        <CircularProgress />
                    </Box>
                }



            </Popover>
        </>
    );
}
